





































































import {defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import commonUtils from "@/common/commonUtils";
import {AxiosResponse} from "axios";
import Main from '@/views/layouts/Main.vue'
import Navigation from '@/components/Navigation.vue'
import getCurrentUserData from "@/common/userData";

export default defineComponent({
  components: {
    Main,
    Navigation
  },
  setup(props, ctx) {
    const loadedUser = ref(null);
    const data = reactive({
      pageName: 'Beleške ',
      notes: [],
      content: '',
      userId: 0,
      currentUser: null
    });
    const {get, insert} = commonUtils()

    function addNote() {
      insert.create('api/notes', {
        content: data.content,
        "receiver_id": data.userId,
        "author_id": data.currentUser.id,
      })
          .then(() => {
            data.content = ''

            get.index('api/notes/' + data.userId)
                .then((response: AxiosResponse) => {
                  data.notes = response.data;
                })
          })
    }

    onMounted(() => {
      data.userId = parseInt(ctx.root.$route.params.user_id);

      data.currentUser = localStorage.getItem('loggedInUserData')
      if (data.currentUser)
        data.currentUser = JSON.parse(data.currentUser)

      getCurrentUserData(data.userId)
          .then((response) => {
            loadedUser.value = response.value
            data.pageName = data.pageName + ' ['+loadedUser.value.name+']'
          })

      get.index('api/notes/' + data.userId)
          .then((response: AxiosResponse) => {
            data.notes = response.data;
          })
    })

    return {
      data,
      addNote
    };
  },
});
