
































































import {defineComponent, reactive} from "@vue/composition-api";
import axios, {AxiosResponse} from 'axios'
import apiUtils from "@/common/utils";
import VueRecaptcha from 'vue-recaptcha';
import generator from 'generate-password';

export default defineComponent({
  components: {VueRecaptcha},
  setup() {
    const {apiPrefix} = apiUtils()
    const data = reactive({
      pageName: 'Registracija',
      email: null,
      password: '',
      name: null,
      registerSuccess: false,
      verified: false,
      secretKey: '6LfIk_EaAAAAAAjYpHbYP3UdIvDUE0HKWU3s3o1I',
      existingEmail: false
    });

    function generate() {
      data.password = generator.generate({
        length: 10,
        numbers: true
      })
    }

    function onVerify(response) {
      if (response) {
        data.verified = true;
      }
    }

    function onExpired(response) {
      if (response) {
        data.verified = false;
      }
    }

    function registerMe() {
      // if (!data.verified) {
      //   return false
      // }
      axios
          .post(apiPrefix + 'api/auth/register', {
            email: data.email,
            name: data.name,
            password: data.password
          })
          .then((response: AxiosResponse) => {
            if (response.data.status === 201) {
              data.registerSuccess = true
              data.existingEmail = false
            }
            if (response.data.status === 400) {
              data.existingEmail = true
            }

          })
          .catch(err => {
            console.log(err)
            data.existingEmail = true
          })
    }

    return {
      data,
      registerMe,
      generate,
      onExpired,
      onVerify
    };
  },
});
