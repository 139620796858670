













































































































































import Main from "@/views/layouts/Main.vue";
import {computed, defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import Modal from "@/components/Modal.vue";
import Datepicker from 'vuejs-datepicker';
import {bus} from "@/main";
import commonUtils from "@/common/commonUtils";
import axios, {AxiosResponse} from "axios";
import '@trevoreyre/autocomplete-vue/dist/style.css'
import {UserType} from '@/common/types/all'
import apiUtils from "@/common/utils";

export default defineComponent({
  components: {
    Main,
    Datepicker,
    Modal,
  },
  setup(props) {
    const {
      apiPrefix,
      headers
    } = apiUtils()
    const userService = commonUtils();
    const users = ref<UserType[]>([] as UserType[]);
    const selectedUsers = ref<UserType[]>([] as UserType[]);

    const data = reactive({
      heading: 'Unos nadoknade',
      pageName: 'Nadoknada',
      prefixKey: 666,
      inputCount: 0,
      typed: false,
      selectedUserScreen: false,
      allCandidatesSelected: false,
      candidates: [],
      dialogOpen: false,
      buttons: [],
      sessionDate: new Date(),
      loggedInUser: JSON.parse(localStorage.getItem('loggedInUserData')),
      saveInProgress: false,
      errors: 0,
      allGood: false,
      enteredText: '',
      lengthFree: 0,
      workshopLength: 0
    });

    function onInputChange() {
      data.typed = true;
      data.inputCount = data.enteredText.length;
      if (data.inputCount === 0) {
        data.typed = false;
      }

      if (data.enteredText === '') {
        userService.get.index('api/users/all')
            .then((response: AxiosResponse) => {
              users.value = response.data
            })
      } else {
        userService.get.search(data.enteredText)
            .then((response: AxiosResponse) => {
              users.value = response.data.data
              console.log(response.data)
            })
      }
    }

    function removeUser(user: UserType) {
      selectedUsers.value = selectedUsers.value.filter((el) => {
        return el.name != user.name;
      });
    }

    function selectUser(user: UserType) {
      data.allCandidatesSelected = false;
      if (selectedUsers.value.filter(g => g.name === user.name).length === 0) {
        selectedUsers.value.push(user)
      }
    }

    function goToStepTwo() {
      data.selectedUserScreen = true
    }

    function closeModal(): void {
      data.dialogOpen = false
    }

    onMounted(() => {
      userService.get
          .index('api/users/all')
          .then((response: AxiosResponse) => {
            users.value = response.data
          })

      bus
          .$on('closeModal', () => {
            data.dialogOpen = false
          })
    })

    const modalText = computed(() => {
      let names = '';
      selectedUsers.value.forEach(selectedUser => {
        names += ' [' + selectedUser.name + '] '
      })
      return "Uneli ste sate nadoknade supervizije za korisnike  " + names + ". Da li ste sigurni?"
    })

    const lengthInDays = computed(() => {
      const temp = (data.lengthFree > 1) ? data.lengthFree : data.workshopLength
      if (typeof temp === 'string') {
        return parseInt(temp)
      } else {
        return temp;
      }
    })

    function saveWorkShop() {

      axios
          .post(apiPrefix + 'api/events/extra-supervision', {
            sessionDate: data.sessionDate,
            users: selectedUsers.value
          }, headers.value)
          .then(() => {
            data.allGood = true;
            data.saveInProgress = false;
            data.errors = 0;
            setTimeout(() => {
              data.allGood = false;
            }, 4000)
          })
          .catch(err => {
            console.log(err);
          });

      data.dialogOpen = false

    }

    async function openModal() {
      selectedUsers.value.forEach(user => {
        if (user.numHours === 0 || user.numHours === undefined || user.numHours === null) {
          data.errors++
        }
      })

      if (data.errors) {
        data.saveInProgress = true
      } else {
        data.dialogOpen = true
        data.errors = 0;
        data.saveInProgress = false
      }
    }

    return {
      data,
      onInputChange,
      selectUser,
      openModal,
      closeModal,
      modalText,
      saveWorkShop,
      goToStepTwo,
      removeUser,
      props,
      lengthInDays,

      //refs
      users,
      selectedUsers
    };
  },
});
