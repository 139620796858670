





















import {defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import Main from '@/views/layouts/Main.vue'
import Navigation from '@/components/Navigation.vue'
import ProfileEdit from '@/components/ProfileEdit.vue'
import axios, {AxiosResponse} from "axios";
import apiUtils from "@/common/utils";
import {bus} from "@/main";
import getCurrentUserData from '@/common/userData'

export default defineComponent({
  components: {
    Main,
    ProfileEdit,
    Navigation
  },
  setup(_, ctx) {
    const data = reactive({
      pageName: 'Dosije korisnika',
      pageDescription: 'Podaci korisnika'
    });

    const {apiPrefix, headers} = apiUtils()
    const loadedUser = ref(null);

    function getUserData(userId) {
      axios
          .get(apiPrefix + 'api/users/' + userId, headers.value)
          .then((response: AxiosResponse) => {
            loadedUser.value = response.data
            data.pageName = data.pageName + ' ['+loadedUser.value.name+']'
          })
          .catch(err => console.log(err))
    }

    onMounted(() => {
      const userId = parseInt(ctx.root.$route.params.user_id);

      getCurrentUserData(userId)
      .then((response) => {
        loadedUser.value = response.value
        data.pageName = data.pageName + ' ['+loadedUser.value.name+']'
      })

      bus.$on('refreshUserData', (userId) => {
        getUserData(userId)
      })

      axios
          .post(apiPrefix + 'api/users/last-used', {'user_id': userId}, headers.value)
          .then()
          .catch(err => console.log(err))
    })

    return {
      data,
      loadedUser
    };
  },
});
