var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-holder"},[(_vm.props.userId > 0)?_c('div',{staticClass:"tabs"},_vm._l(([
            {
              url: 'api/events/' + _vm.props.userId + '/attended-events/' + 1,
              name: 'Individualne Seanse',
              component: 'Event',
              category: 1,
              userId: _vm.props.userId,
            },
            {
              url: 'api/events/' + _vm.props.userId + '/attended-events/' + 2,
              name: 'Individualna Supervizija',
              component: 'Event',
              category: 2,
              userId: _vm.props.userId,
            },
            {
              url:
                'api/workshops/' + _vm.props.userId + '/attended-workshops/' + 1,
              name: 'Radionica',
              component: 'WorkShop',
              category: 1,
            },
            {
              url:
                'api/workshops/' + _vm.props.userId + '/attended-workshops/' + 2,
              name: 'Grupna Supervizija',
              component: 'WorkShop',
              category: 2,
            },
            {
              url:
                'api/workshops/' + _vm.props.userId + '/attended-workshops/' + 3,
              name: 'Alternativni sati',
              component: 'WorkShop',
              category: 3,
            } ]),function(tab,index){return _c('div',{key:index,staticClass:"tab",on:{"click":function($event){return _vm.openTab(tab, index)}}},[_c('input',{attrs:{"type":"radio","id":'rd' + index,"name":'rd'}}),_c('label',{staticClass:"tab-label",attrs:{"for":'rd' + index}},[_vm._v(_vm._s(tab.name))]),_c('div',{staticClass:"tab-content"},[(_vm.data.activeEvent === index)?_c(tab.component,{tag:"component",attrs:{"url":tab.url,"category":tab.category,"user-id":_vm.props.userId}}):_vm._e()],1)])}),0):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }