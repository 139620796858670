













import {defineComponent, reactive} from "@vue/composition-api";
import Main from "@/views/layouts/Main.vue";
import Workshop from '@/views/workshop/Workshop.vue';

export default defineComponent({
  components: {
    Main,
    Workshop
  },
  setup() {

    const data = reactive({
      workshopTypeId: 3,
      heading: 'Unos alternativnih sati',
      pageName: 'Alternativni sati'
    });

    return {
      data
    };
  },
});
