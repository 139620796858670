




































import {defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import apiUtils from "@/common/utils";
import SlidingPagination from 'vue-sliding-pagination'
import {bus} from "@/main";
import {AxiosResponse} from "axios";
import Main from "@/views/layouts/Main.vue";
import Navigation from "@/components/Navigation.vue";
import getCurrentUserData from "@/common/userData";

export default defineComponent({
  components: {
    SlidingPagination,
    Main,
    Navigation
  },
  destroyed() {
    bus.$destroy();
  },
  setup(props, ctx) {
    const loadedUser = ref(null);
    const data = reactive({
      pageName: 'Teme korisnika ',
      topics: [],
      loadedId: 0,
      userId: 0,
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
    });
    const {
      read,
      apiPrefix
    } = apiUtils()

    function getTopics(query?: {
      url?: string;
      page?: number;
    }) {
      read
          .getTopics(query)
          .then((response: AxiosResponse) => {
            data.topics = response.data.data;
            const totalPagesMod = (response.data.total % data.pageSize)
            data.totalPages = Math.floor(response.data.total / data.pageSize)
            if (totalPagesMod > 0) {
              data.totalPages++
            }

          })
    }

    onMounted(() => {
      data.userId = parseInt(ctx.root.$route.params.user_id);
      getTopics({url: apiPrefix + 'api/users/' + data.userId + '/topics'});

      getCurrentUserData(data.userId)
          .then((response) => {
            loadedUser.value = response.value
            data.pageName = data.pageName + ' [' + loadedUser.value.name + ']'
          })
    })

    function pageChangeHandler(selectedPage) {
      data.currentPage = selectedPage
      getTopics({page: data.currentPage})
    }

    return {
      data,
      apiPrefix,
      pageChangeHandler
    };
  },
});
