































































import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import Main from '@/views/layouts/Main.vue'
import apiUtils from "@/common/utils";
import rolecheck from "@/common/rolecheck";
import {bus} from "@/main";
import {AxiosResponse} from "axios";
import commonUtils from "@/common/commonUtils";
import SlidingPagination from 'vue-sliding-pagination'

interface Topic {
  id?: number;
  name: string;
}

export default defineComponent({
  components: {
    Main,
    SlidingPagination
  },
  destroyed() {
    bus.$destroy();
  },
  setup() {
    const {
      read,
      insert,
      update
    } = apiUtils()

    const {actions} = commonUtils()

    const {
      isAdmin
    } = rolecheck()

    const data = reactive({
      pageName: 'Topics',
      name: '',
      topics: [],
      editMode: false,
      loadedId: 0,
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
    });

    function getTopics(query?: {
      url?: string;
      page?: number;
    }) {
      read
          .getTopics(query)
          .then((response: AxiosResponse) => {
            data.topics = response.data.data;
            const totalPagesMod = (response.data.total % data.pageSize)
            data.totalPages = Math.floor(response.data.total / data.pageSize)
            if (totalPagesMod > 0) {
              data.totalPages++
            }

          })
    }

    function saveTopic() {
      if (data.editMode) {
        update
            .updateTopic({
              id: data.loadedId,
              name: data.name
            })
            .then(() => {
              getTopics();
              data.name = ''
              data.editMode = false
              data.loadedId = 0
            })
      } else {
        insert
            .insertTopic({
              name: data.name
            })
            .then(() => {
              getTopics();
              data.name = ''
            })
      }
    }

    function edit(topic: Topic) {
      data.name = topic.name
      data.editMode = true
      data.loadedId = topic.id
    }

    function deleteTopic(topicId) {
      actions
          .remove('api/topics/' + topicId)
          .then((response: AxiosResponse) => {
            getTopics()
          })
          .catch(err => console.log(err))
    }

    onMounted(() => {
      getTopics();
    });

    function pageChangeHandler(selectedPage) {
      data.currentPage = selectedPage
      getTopics({page: data.currentPage})
    }

    return {
      data,
      edit,
      saveTopic,
      isAdmin,
      deleteTopic,
      pageChangeHandler
    };
  },
});
