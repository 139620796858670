




































































































import {computed, defineComponent, onMounted, reactive} from "@vue/composition-api";
import Main from '@/views/layouts/Main.vue'
import commonUtils from "@/common/commonUtils.ts";
import SlidingPagination from 'vue-sliding-pagination'
import {bus} from "@/main";
import {AxiosResponse} from "axios";
import apiUtils from "@/common/utils";
import Datepicker from 'vuejs-datepicker';

export default defineComponent({
  components: {
    Main,
    SlidingPagination,
    Datepicker
  },
  destroyed() {
    bus.$destroy();
  },
  setup() {
    const {get, insert, actions} = commonUtils()

    const data = reactive({
      pageName: 'Vesti',
      title: '',
      content: '',
      posts: [],
      page: 1,
      userId: null,
      currentUser: null,
      toBeHeld: null,
      currentPage: 1,
      totalPages: 0,
      pageSize: 10
    });

    function getPosts(query?: {
      url?: string;
      page?: number;
    }) {
      let apiUrl = 'api/posts/';

      if (query != undefined) {
        if (query.url != undefined) {
          apiUrl = query.url
        }
        if (query.page != undefined) {
          apiUrl = `api/posts/?page=${query.page}`;
        }
      }

      get
          .index(apiUrl)
          .then((response: AxiosResponse) => {
            data.posts = response.data.data
            const totalPagesMod = (response.data.total % data.pageSize)
            data.totalPages = Math.floor(response.data.total / data.pageSize)
            if (totalPagesMod > 0) {
              data.totalPages++
            }
          })
          .catch(err => console.log(err))
    }

    const {
      apiPrefix
    } = apiUtils()

    function addPost() {
      insert
          .create('api/posts', {
            title: data.title,
            content: data.content,
            'to_be_held': data.toBeHeld,
            // eslint-disable-next-line @typescript-eslint/camelcase
            author_id: data.currentUser.id
          })
          .then(() => {
            getPosts()
          })
    }

    function deleteArticle(postId) {

      actions
          .remove('api/posts/' + postId)
          .then((response: AxiosResponse) => {
            getPosts()
          })
          .catch(err => console.log(err))
    }
    
    const isAdmin = computed(() => {
      return data.currentUser.role.name === 'admin'
    })

    onMounted(() => {
      data.currentUser = localStorage.getItem('loggedInUserData')
      if (data.currentUser)
        data.currentUser = JSON.parse(data.currentUser)

      getPosts()
    })

    function pageChangeHandler(selectedPage) {
      data.currentPage = selectedPage
      getPosts({page: data.currentPage})
    }

    return {
      data,
      apiPrefix,
      addPost,
      isAdmin,
      deleteArticle,
      pageChangeHandler
    };
  },
});
