










































































































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import Main from "@/views/layouts/Main.vue";
import commonUtils from "@/common/commonUtils";
import apiService from "@/api/actions";
import { bus } from "@/main";
import Datepicker from "vuejs-datepicker";
import SlidingPagination from "vue-sliding-pagination";
import Modal from "@/components/Modal.vue";
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";
import Autocomplete from "@trevoreyre/autocomplete-vue";
import type {
  SingleEventResponse,
  CandidateResponse,
} from "@/common/types/response";

export default defineComponent({
  components: {
    Main,
    Datepicker,
    SlidingPagination,
    Modal,
    VueDatePicker,
    Autocomplete,
  },
  destroyed() {
    bus.$destroy();
  },
  setup(_, context) {
    const route = context.root.$route;

    const { insert } = commonUtils();
    const { apiActions } = apiService();

    const data = reactive({
      pageName:
        "Korektor " +
        (route.params.eventTypeId == "1"
          ? "individualne terapije"
          : "individualne supervizije"),
      events: [],
      editMode: false,
      loadedId: 0,
      selectedUser: 0,
      numHours: 0,
      selectedTherapist: 0,
      candidates: [],
      therapists: [],
      date: "",
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
      filter: null,
      loadedCandidateId: null,
      enteredCandidateName: "",
      enteredTherapistName: "",
      loadedOwnerId: null,
      loadedDate: null,
      loadedEventId: null,
      deleteModal: false,
      dialogOpen: true,
      eventToDelete: null,
      test: null,
    });

    function getEvents(query?: { url?: string; page?: number }) {
      let apiUrl = `api/events?eventTypeId=${route.params.eventTypeId}`;

      if (query != undefined) {
        if (query.url != undefined) {
          apiUrl = query.url;
        }
        if (query.page != undefined) {
          apiUrl += `&page=${query.page}`;
        }
      }

      apiActions
        .index<SingleEventResponse>(apiUrl)
        .then((response) => {
          data.events = response.data;
          const totalPagesMod = response.total % data.pageSize;
          data.totalPages = Math.floor(response.total / data.pageSize);
          if (totalPagesMod > 0) {
            data.totalPages++;
          }
        })
        .catch((err) => console.log(err));
    }

    function applyFilter(overrideCurrentPage?: number) {
      data.filter = {
        user: data.loadedCandidateId,
        owner: data.loadedOwnerId,
        date: data.loadedDate,
      };

      if (overrideCurrentPage) {
        data.currentPage = 1;
      }

      const routeApi = `api/events-filter?eventTypeId=${route.params.eventTypeId}&page=${data.currentPage}`;

      apiActions
        .filter<SingleEventResponse>(routeApi, data.filter)
        .then((response) => {
          data.events = response.data;
          const totalPagesMod = response.total % data.pageSize;
          data.totalPages = Math.floor(response.total / data.pageSize);
          if (totalPagesMod > 0) {
            data.totalPages++;
          }
        })
        .catch((err) => console.log(err));
    }

    const autocomplete = ref(null);

    function resetFilter() {
      data.selectedUser = 0;
      data.date = "";
      data.enteredCandidateName = "";
      data.enteredTherapistName = "";
      data.filter = null;
      data.currentPage = 1;
      data.loadedCandidateId = null;
      data.loadedOwnerId = null;
      data.date = null;
      data.numHours = null;
      getEvents();
    }

    function saveEvent() {
      insert
        .update("api/events/" + data.loadedEventId, {
          // eslint-disable-next-line @typescript-eslint/camelcase
          owner_id: data.loadedOwnerId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          candidate_id: data.loadedCandidateId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          session_date: data.loadedDate,
          // eslint-disable-next-line @typescript-eslint/camelcase
          num_hours: data.numHours,
        })
        .then(() => {
          resetFilter();
          data.editMode = false;
        });
    }

    function prepareDelete(eventId): void {
      data.eventToDelete = eventId;
      data.deleteModal = true;
    }

    function cancelSavingEvent() {
      resetFilter();
      data.editMode = false;
    }

    function pageChangeHandler(selectedPage) {
      data.currentPage = selectedPage;
      if (data.filter === null) {
        getEvents({ page: data.currentPage });
      } else {
        applyFilter();
      }
    }

    function editEvent(event) {
      data.loadedDate = event.session_date; //moment(event.session_date).format('DD.MM.YYYY.')
      data.loadedOwnerId = event.owner_id;
      data.loadedCandidateId = event.candidate_id;
      data.loadedEventId = event.id;
      data.numHours = event.num_hours;

      data.editMode = true;
    }

    function deleteEvent() {
      apiActions
        .remove("api/events/" + data.eventToDelete)
        .then(() => {
          applyFilter();
          data.deleteModal = false;
        })
        .catch((err) => console.log(err));
    }

    onMounted(() => {
      getEvents();

      bus.$on("closeModal", () => {
        data.deleteModal = false;
      });
    });

    async function searchUsers(input) {
      if (input.length < 3) {
        return [];
      }

      return await apiActions
        .index<CandidateResponse>("api/users/search/" + input)
        .then((response) => response.data)
        .catch((err) => console.log(err));
    }

    function getResultValue(result) {
      return result.name;
    }

    function selectCandidate(result) {
      data.loadedCandidateId = result.id;
    }

    function selectTherapist(result) {
      data.loadedOwnerId = result.id;
    }

    function updateCandidateName(e) {
      data.enteredCandidateName = e.srcElement.value;
    }

    function updateTherapistName(e) {
      data.enteredTherapistName = e.srcElement.value;
    }

    return {
      data,
      applyFilter,
      resetFilter,
      editEvent,
      deleteEvent,
      prepareDelete,
      pageChangeHandler,
      cancelSavingEvent,
      saveEvent,
      getResultValue,
      selectCandidate,
      updateCandidateName,
      selectTherapist,
      updateTherapistName,
      searchUsers,
      autocomplete,
    };
  },
});
