


























































import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import Navbar from '@/components/Navbar.vue'
import Sidebar from '@/components/Sidebar.vue'
import Menu from '@/components/Menu.vue'
import {AxiosResponse} from "axios";
import {bus} from "@/main";
import commonUtils from "@/common/commonUtils";
import SlidingPagination from 'vue-sliding-pagination'

export default defineComponent({
  components: {
    Navbar,
    SlidingPagination,
    Sidebar,
    Menu
  },
  destroyed() {
    bus.$destroy();
  },
  setup() {
    const {get} = commonUtils()

    const data = reactive({
      pageName: 'Vesti',
      posts: [],
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
    });

    function getPosts(query?: {
      url?: string;
      page?: number;
    }) {
      let apiUrl = 'api/posts/';

      if (query != undefined) {
        if (query.url != undefined) {
          apiUrl = query.url
        }
        if (query.page != undefined) {
          apiUrl = `api/posts/?page=${query.page}`;
        }
      }

      get
          .index(apiUrl)
          .then((response: AxiosResponse) => {
            data.posts = response.data.data

            const totalPagesMod = (response.data.total % data.pageSize)
            data.totalPages = Math.floor(response.data.total / data.pageSize)
            if (totalPagesMod > 0) {
              data.totalPages++
            }
          })
          .catch(err => console.log(err))
    }

    function pageChangeHandler(selectedPage) {
      data.currentPage = selectedPage
      getPosts({page: data.currentPage})
    }

    onMounted(() => {
      getPosts()
    })

    return {
      data,
      pageChangeHandler
    };
  },
});
