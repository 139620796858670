import Vue from 'vue'
import TheApp from './TheApp.vue'
import VueCompositionAPI from '@vue/composition-api';
import router from './router'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/main.css'
import moment from 'moment'
import vuetify from './plugins/vuetify'
import Vidle from 'v-idle'
import VueScrollTo from 'vue-scrollto';

import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
  componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
});
Vue.use(Vidle)
Vue.use(VueCompositionAPI);
Vue.use(VueScrollTo);
Vue.config.productionTip = false
Vue.prototype.moment = moment

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

export const bus = new Vue();

new Vue({
  router,
  vuetify,
  render: h => h(TheApp)
}).$mount('#app')
