import axios from "axios";
import apiUtils from "@/common/utils";

export default function () {

    const {
        apiPrefix,
        headers
    } = apiUtils()

    return {
        topicService: {
            insert: {
                addTopic: function(topic: {
                    name: string;
                }) {
                    return new Promise((resolve, reject) => {
                        axios
                            .post(apiPrefix + 'api/topics', topic, headers.value)
                            .then((response) => {
                                resolve(response)
                            })
                            .catch(err => {
                                console.log(err);
                                reject();
                            });
                    });
                }
            },
            read: {
                index: function() {
                    return new Promise((resolve, reject) => {
                        axios
                            .get(apiPrefix + 'api/topics', headers.value)
                            .then((response) => {
                                resolve(response)
                            })
                            .catch(err => {
                                console.log(err);
                                reject();
                            });
                    });
                },
                indexAll: function() {
                    return new Promise((resolve, reject) => {
                        axios
                            .get(apiPrefix + 'api/topics/all', headers.value)
                            .then((response) => {
                                resolve(response)
                            })
                            .catch(err => {
                                console.log(err);
                                reject();
                            });
                    });
                },
                search: function(query: string) {
                    return new Promise((resolve, reject) => {
                        axios
                            .get(apiPrefix + 'api/topics-search/' + query, headers.value)
                            .then((response) => {
                                resolve(response)
                            })
                            .catch(err => {
                                console.log(err);
                                reject();
                            });
                    });
                }
            },
            update: {}
        }
    }
}
