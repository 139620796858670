

















import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import axios, {AxiosResponse} from 'axios'
import apiUtils from "@/common/utils";

export default defineComponent({
  setup(props, context) {
    const {apiPrefix} = apiUtils()
    const data = reactive({
      pageName: 'Aktivacija naloga',
      activationStatus: false
    });

    function activate() {

      const route = context.root.$route;

      axios
          .post(apiPrefix + 'api/auth/activate', {
            hash: route.params.hash
          })
          .then((response: AxiosResponse) => {
            if (response.data.status === 200) {
              data.activationStatus = true
            }
          })
          .catch(err => {
            console.log(err)
          })
    }

    onMounted(() => {
      activate()
    })

    return {
      data
    };
  },
});
