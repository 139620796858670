







import {defineComponent, onMounted, ref, PropType} from "@vue/composition-api";
import commonUtils from "@/common/commonUtils";
import {bus} from "@/main";
import type {LineItem} from '@/common/types/all'

export default defineComponent({
  props: {
    lineItem: {
      type: Object as PropType<LineItem>,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  destroyed() {
    bus.$destroy();
  },
  setup(props) {
    const checked = ref<boolean>(false);
    const { insert } = commonUtils()

    const getValue = () => {
      return props.lineItem[props.type]
    }

    const toggleValue = () => {
      checked.value = !checked.value
      insert
          .update('api/events/line-item/' + props.lineItem.workshop_id + '/' + props.lineItem.user_id, { type: props.type, value: checked.value })
          .then(() => {
            bus.$emit('refreshStats')
          })
    }

    onMounted(() => {
      checked.value = getValue()
    })

    return {
      getValue,
      toggleValue,
      checked
    };
  },
});
