import axios from "axios";
import apiUtils from "@/common/utils";

export default function () {

    const {
        apiPrefix,
        headers
    } = apiUtils()

    function getCandidatesForGroups(groupIds: number[]) {
        return new Promise((resolve, reject) => {
            axios
                .post(apiPrefix + 'api/users-of-groups', {groups: groupIds}, headers.value)
                .then((response) => {
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject();
                });
        });
    }

    function getAllCandidates() {
        return new Promise((resolve, reject) => {
            axios
                .get(apiPrefix + 'api/users/all', headers.value)
                .then((response) => {
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject();
                });
        });
    }

    function getGroupsFiltered(userId: number, query?: string) {

        return new Promise((resolve, reject) => {
            if (query === undefined) {
                query = ''
            } else {
                query = '/' + query
            }
            axios
                .get(apiPrefix + 'api/groups/' + userId + '/most-recent-filtered' + query, headers.value)
                .then((response) => {
                    resolve(response)
                })
                .catch(err => {
                    console.log(err);
                    reject();
                });
        });
    }

    return {
        groupService: {
            insert: {},
            read: {
                getGroupsFiltered,
                getCandidatesForGroups,
                getAllCandidates
            },
            update: {}
        }
    }
}
