





























import {computed, defineComponent, onMounted, reactive} from "@vue/composition-api";
import Menu from '@/components/Menu.vue'
import apiUtils from "@/common/utils";

export default defineComponent({
  components: {
    Menu
  },
  setup() {
    const {apiPrefix} = apiUtils()

    const data = reactive({
      user: null
    });

    const userProfileImage = computed(() => {
      if (data.user && data.user.profile_image)
        return apiPrefix + 'files/' + data.user.profile_image
      else
        return false
    });

    onMounted(() => {
      setTimeout(() => {
        const userDataString = localStorage.getItem('loggedInUserData')
        if (userDataString)
          data.user = JSON.parse(userDataString)

      }, 500)
    })

    return {
      data,
      userProfileImage
    };
  },
});
