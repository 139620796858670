











































































































import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import Main from '@/views/layouts/Main.vue'
import commonUtils from "@/common/commonUtils.ts";
import {bus} from "@/main";
import {AxiosResponse} from "axios";
import SlidingPagination from 'vue-sliding-pagination'

export default defineComponent({
  components: {
    Main,
    SlidingPagination
  },
  destroyed() {
    bus.$destroy();
  },
  setup() {
    const {get, insert} = commonUtils()

    const data = reactive({
      pageName: 'Korisnici',
      name: '',
      email: '',
      roleId: 0,
      password: '',
      parent: '',
      users: [],
      editMode: false,
      loadedId: 0,
      group: 0,
      groups: [],
      currentPage: 1,
      totalPages: 0,
      pageSize: 10
    });

    function getUsers(query?: {
      url?: string;
      page?: number;
    }) {
      let apiUrl = 'api/users/management/all';

      if (query != undefined) {
        if (query.url != undefined) {
          apiUrl = query.url
        }
        if (query.page != undefined) {
          apiUrl = `api/users/management/all?page=${query.page}`;
        }
      }

      get
          .index(apiUrl)
          .then((response: AxiosResponse) => {
            const totalPagesMod = (response.data.total % data.pageSize)
            data.totalPages = Math.floor(response.data.total / data.pageSize)
            if (totalPagesMod > 0) {
              data.totalPages++
            }

            data.users = response.data.data
          })
          .catch(err => console.log(err))
    }

    function getGroups() {
      get
          .index('api/groups')
          .then((response: AxiosResponse) => {
            data.groups = response.data
          })
          .catch(err => console.log(err))
    }

    function saveUser() {
      if (!data.name && !data.email && !data.group && !data.password && !data.roleId) {
        return false;
      }

      if (data.editMode) {
        insert
            .update('api/users/' + data.loadedId, {
              name: data.name,
              email: data.email,
              groupId: data.group,
              password: data.password,
              roleId: data.roleId,
              parent: data.parent,
            })
            .then(async () => {
              getUsers()
              data.name = ''
              data.editMode = false
              data.name = ''
              data.email = ''
              data.parent = ''
              data.password = ''
              data.group = 0
              data.roleId = 0
            })
            .catch(err => console.log(err))
      } else {
        insert
            .create('api/users', {
              name: data.name,
              email: data.email,
              groupId: data.group,
              password: data.password,
              parent: data.parent,
              roleId: data.roleId,
            })
            .then(async () => {
              getUsers()
              data.name = ''
              data.email = ''
              data.parent = ''
              data.password = ''
              data.group = 0
              data.roleId = 0
            })
            .catch(err => console.log(err))
      }
    }

    function editUser(user: {
      id: number;
      name: string;
      parent: string;
      email: string;
      groupId: number;
      password: string;
      role_id: number;
      group: {
        name: string;
        id: number;
      };
    }) {
      data.name = user.name
      data.email = user.email
      data.parent = user.parent
      data.group = (user.group !== null) ? user.group.id : null
      data.editMode = true
      data.roleId = user.role_id
      data.loadedId = user.id
    }

    function pageChangeHandler(selectedPage) {
      data.currentPage = selectedPage
      getUsers({page: data.currentPage})
    }

    onMounted(() => {
      getUsers()
      getGroups()
    })


    return {
      data,
      saveUser,
      editUser,
      pageChangeHandler
    };
  },
});
