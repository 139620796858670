










import {defineComponent} from '@vue/composition-api';
import apiUtils from "@/common/utils";
import axios from 'axios'
import {bus} from "@/main";

export default defineComponent({
  name: 'TheApp',
  data() {
    return {
      user: null
    }
  },
  computed: {
    isLoggedIn() {
      return this.user !== null && this.user.email !== 'slavisadev@gmail.com'
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('loggedInUserData'));

    bus.$on('sidebarLogOut', () => {
      this.user = null;
    })
  },
  methods: {
    onidle() {
      alert('Izlogovani ste iz sistema');
      this.user = null;
      this.apiLogOut();
    },
    apiLogOut() {
      const {apiPrefix, headers} = apiUtils()
      axios
          .post(apiPrefix + 'api/auth/logout', null, headers.value)
          .then(() => {
            this.logout()
          })
          .catch(err => {
            console.log(err)
            this.logout()
          })
    },
    logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('loggedInUserData')
      localStorage.removeItem('rememberMe')

      this.$router.push({name: 'login'})
    }
  }
});
