
















































































import {defineComponent, onMounted, ref} from "@vue/composition-api";
import axios from "axios";
import apiUtils from "@/common/utils";
import {bus} from "@/main";

export default defineComponent({

  setup(_, ctx) {
    const {apiPrefix, headers} = apiUtils();

    const isAdmin = ref(false);
    const isAdminMod = ref(false);
    const isStudent = ref(false);

    function logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('loggedInUserData')
      localStorage.removeItem('rememberMe')

      ctx.root.$router.push({name: 'login'})

      bus.$emit('sidebarLogOut', 1)
    }

    function apiLogOut() {
      axios
          .post(apiPrefix + 'api/auth/logout', null, headers.value)
          .then(() => {
            logout()
          })
          .catch(err => {
            console.log(err)
            logout()
          })
    }

    onMounted(() => {

      setTimeout(() => {
        const user = JSON.parse(localStorage.getItem('loggedInUserData'));

        if (user.role.name === 'admin') {
          isAdmin.value = true
        }
        if (user.role.name === 'educator' || user.role.name === 'admin') {
          isAdminMod.value = true
        }
        if (user.role.name === 'candidate') {
          isStudent.value = true
        }

      }, 1500)

    })
    return {
      apiLogOut,
      isAdmin,
      isStudent,
      isAdminMod
    };
  },
});
