























































import {defineComponent, reactive} from "@vue/composition-api";
import axios, {AxiosResponse} from 'axios'
import apiUtils from "@/common/utils";

export default defineComponent({
  setup(_, ctx) {
    const {apiPrefix} = apiUtils()
    const data = reactive({
      pageName: 'Login',
      email: null,
      password: null,
      rememberMe: false,
      invalidParameters: false,
      message: 'Molimo unesite email i lozinku.'
    });

    function getUserData() {
      const token = localStorage.getItem('token')
      const tokens = token.split('|')

      axios.get(apiPrefix + 'api/me', {
        headers: {
          Authorization: 'Bearer ' + tokens[1]
        }
      })
          .then((response: AxiosResponse) => {
            localStorage.setItem('loggedInUserData', JSON.stringify(response.data))
          }).catch(err => console.log(err))
    }

    function logIn() {

      if (!data.email || !data.password) {
        data.message = 'Molimo unesite email i lozinku.'
        data.invalidParameters = true;
        return;
      }

      data.invalidParameters = false;

      axios.post(apiPrefix + 'api/auth/login', {
        email: data.email,
        password: data.password
      }).then(async (response) => {

        localStorage.setItem('token', response.data.data.token)
        const remember = (data.rememberMe) ? 'yes' : 'no'
        localStorage.setItem('rememberMe', remember)

        await getUserData()

        ctx.root.$router.push({name: 'home'})

      }).catch(error => {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        if (error.response.status === 422) {
          data.message = 'Došlo je do greške. Molimo proverite unete podatke.'
          data.invalidParameters = true;
        } else if (error.response.status === 401) {
          data.message = 'Došlo je do greške. Molimo proverite lozinku koju ste uneli.'
          data.invalidParameters = true;
        } else if (error.response.status === 500) {
          data.message = 'Došlo je do greške. Postoji serverski problem koji ce uskoro biti uklonjen.'
          data.invalidParameters = true;
        }
      })
    }

    return {
      data,
      logIn
    };
  },
});
