import {ref} from "@vue/composition-api";
import axios from "axios";
import logoutCheck from './logout-check'

export default function () {

  const headers = ref({});
  const apiPrefix = 'https://api.psihoterapeut.rs/';

  function getHeaders() {
    const token = localStorage.getItem('token')
    if (token === null) {
      return '';
    }
    const tokens = token.split('|')

    return {
      headers: {
        Authorization: 'Bearer ' + tokens[1]
      }
    };
  }

  function getUsersForEducator(educatorId: number) {
    return axios
      .get(apiPrefix + 'api/users/' + educatorId + '/most-recent', headers.value)
      .then((response) => response.data)
      .catch(error => {
        if (error.response.status === 401) {
          logoutCheck()
        }
      });
  }

  function getGroups() {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPrefix + 'api/groups', headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  function getWorkshopHistory(groupId: number) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPrefix + 'api/groups/' + groupId + '/workshops', headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  function insertWorkShopAdditional(workShop: {
    user_ids: number[];
    session_date: any;
    topic_id: number | string;
    ownerId: number;
    category_id: number;
    name: string;
    length: number;
    line_items: {
      user_id: any;
      num_hours: any;
      present: any;
      paid: any;
      work: any;
      exam: any;
    }[];
  }) {
    return axios
      .post(apiPrefix + 'api/workshops/additional', workShop, headers.value)
      .then((response) => response.data)
      .catch(error => {
        console.log(error)
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      })
  }

  function insertWorkShop(workShop: {
    group_ids: number[];
    session_date: any;
    topic_id: number | string;
    ownerId: number;
    category_id: number;
    name: string;
    length: number;
    line_items: {
      user_id: any;
      num_hours: any;
      present: any;
      paid: any;
      work: any;
      exam: any;
    }[];
  }) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPrefix + 'api/workshops', workShop, headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  function insertAttachment(userId: number, formData: FormData) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPrefix + 'api/users/' + userId + '/attachments', formData, headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  function insertSchedule(formData: FormData) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPrefix + 'api/schedules', formData, headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  function insertTopic(topic: {
    id?: number;
    name: string;
  }) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPrefix + 'api/topics', topic, headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  function updateTopic(topic: {
    id: number;
    name: string;
  }) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPrefix + 'api/topics/' + topic.id, topic, headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  function getAttachments(userId: number) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPrefix + 'api/users/' + userId + '/attachments', headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  function getSchedules() {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPrefix + 'api/schedules', headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  function getCategories() {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPrefix + 'api/file-categories', headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  function getTopics(query?: {
    url?: string;
    page?: number;
  }) {
    return new Promise((resolve, reject) => {
      let apiUrl = apiPrefix + 'api/topics';

      if (query != undefined) {
        if (query.url != undefined) {
          apiUrl = query.url
        }
        if (query.page != undefined) {
          apiUrl = apiPrefix + `api/topics/?page=${query.page}`;
        }
      }

      axios
        .get(apiUrl, headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  headers.value = getHeaders()

  return {
    apiPrefix,
    headers,
    getUsersForEducator,
    getGroups,
    insert: {
      insertWorkShop,
      insertTopic,
      insertAttachment,
      insertSchedule,
      insertWorkShopAdditional,
    },
    read: {
      getWorkshopHistory,
      getTopics,
      getAttachments,
      getCategories,
      getSchedules
    },
    update: {
      updateTopic
    }
  }
}
