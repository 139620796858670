



















































import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import Main from '@/views/layouts/Main.vue'
import commonUtils from "@/common/commonUtils.ts";
import SlidingPagination from 'vue-sliding-pagination'
import {bus} from "@/main";
import {AxiosResponse} from "axios";
import apiUtils from "@/common/utils";

export default defineComponent({
  components: {
    Main,
    SlidingPagination
  },
  destroyed() {
    bus.$destroy();
  },
  setup() {
    const {get, actions} = commonUtils()

    const data = reactive({
      pageName: 'Fajlovi studenata',
      selectedCategory: 0,
      files: [],
      categories: [],
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
    });

    function getFiles(query?: {
      url?: string;
      page?: number;
    }) {
      let apiUrl = 'api/files';

      if (query != undefined) {
        if (query.url != undefined) {
          apiUrl = query.url
        }
        if (query.page != undefined) {
          apiUrl = `api/files?page=${query.page}`;
        }
      }

      get
          .index(apiUrl)
          .then((response: AxiosResponse) => {
            data.files = response.data.data
            const totalPagesMod = (response.data.total % data.pageSize)
            data.totalPages = Math.floor(response.data.total / data.pageSize)
            if (totalPagesMod > 0) {
              data.totalPages++
            }
          })
          .catch(err => console.log(err))
    }

    function getCategories() {
      get
          .index('api/file-categories')
          .then((response: AxiosResponse) => {
            data.categories = response.data
          })
          .catch(err => console.log(err))
    }

    function deleteFile(fileId) {

      actions.remove('api/files/' + fileId)
          .then((response: AxiosResponse) => {
            data.selectedCategory = 0;
            console.log(response);
            getFiles()
          })
          .catch(err => console.log(err))
    }

    const {
      apiPrefix
    } = apiUtils()

    onMounted(() => {
      getFiles()
      getCategories()
    })

    function pageChangeHandler(selectedPage) {
      data.currentPage = selectedPage
      getFiles({page: data.currentPage})
    }

    return {
      data,
      deleteFile,
      apiPrefix,
      pageChangeHandler
    };
  },
});
