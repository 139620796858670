






















































import {defineComponent, reactive} from "@vue/composition-api";
import Modal from "@/components/Modal.vue";
import History from "@/components/History.vue";
import WorkshopUserItems from "@/views/workshop/WorkshopUserItem.vue";
import Datepicker from 'vuejs-datepicker';
import {bus} from "@/main";
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

export default defineComponent({
  components: {
    Datepicker,
    History,
    Modal,
    Autocomplete,
    WorkshopUserItems
  },
  props: {
    loadedCandidate: Object,
    candidateIndex: Number,
    length: Number,
    markings: Array
  },
  setup(props) {

    const data = reactive({
      candidateOptions: [
        ['present', 'Prisutan'],
        ['paid', 'Platio'],
        ['work', 'Rad'],
        ['exam', 'Ispit'],
      ]
    });

    function isCandidatePresentGeneric(candidateIndex, slug) {
      return props.markings[candidateIndex][slug + '_active'] ? 'active' : 'inactive';
    }

    function markCandidate(candidateIndex: number, markingType?: string) {
      props.markings[candidateIndex][markingType + '_active'] = !props.markings[candidateIndex][markingType + '_active']
      const isPresent = props.markings[candidateIndex]['present_active'];
      if(!props.length) {
        props.length = 1;
      }
      if(!isPresent) {
        props.loadedCandidate.numHours = 0;
      } else {
        props.loadedCandidate.numHours = props.length * 7;
      }
      bus.$emit('markings', props.markings)
      bus.$emit('toggleMarkingTypes', {candidateIndex, markingType})
    }

    return {
      data,
      markCandidate,
      isCandidatePresentGeneric
    };
  },
});
