





















import {defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import Main from '@/views/layouts/Main.vue'
import Navigation from '@/components/Navigation.vue'
import ProfileEdit from '@/components/ProfileEdit.vue'
import getUserData from '@/common/refreshUserData'
import {bus} from '@/main'

export default defineComponent({
  components: {
    Main,
    Navigation,
    ProfileEdit
  },
  setup() {
    const data = reactive({
      pageName: 'Dosije',
      pageDescription: 'Podaci korisnika'
    });

    const loadedUser = ref({});

    function refreshUserData() {
      getUserData()
          .then(() => {
            loadedUser.value = JSON.parse(localStorage.getItem('loggedInUserData'))
          })
    }

    onMounted(() => {
      refreshUserData()

      bus.$on('refreshUserData', () => {
        refreshUserData()
      })
    })

    return {
      data,
      loadedUser
    };
  },
});
