

























































































































































import Main from "@/views/layouts/Main.vue";
import {computed, defineComponent, onMounted, reactive, watch, ref} from "@vue/composition-api";
import Modal from "@/components/Modal.vue";
import History from "@/components/History.vue";
import WorkshopUserItem from "@/views/workshop/WorkshopUserItem.vue";
import Datepicker from 'vuejs-datepicker';
import {bus} from "@/main";
import apiUtils from "@/common/utils";
import topicUtils from "@/common/topic-utils";
import commonUtils from "@/common/commonUtils";
import {AxiosResponse} from "axios";
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import {UserType} from '@/common/types/all'

export default defineComponent({
  components: {
    Main,
    Datepicker,
    History,
    Modal,
    Autocomplete,
    WorkshopUserItem
  },
  setup(props) {
    const {
      insert
    } = apiUtils()

    const {
      topicService
    } = topicUtils()

    const userService = commonUtils();

    const users = ref<UserType[]>([] as UserType[]);
    const selectedUsers = ref<UserType[]>([] as UserType[]);

    const data = reactive({
      heading: 'Unos nadoknade',
      pageName: 'Nadoknada',
      prefixKey: 666,
      inputCount: 0,
      enteredTopicName: '',
      enteredText: '',
      typed: false,
      selectedUserScreen: false,
      allCandidatesSelected: false,
      candidates: [],
      topics: [],
      selectedTopic: 'not-selected',
      buttons: [],
      dialogOpen: false,
      sessionDate: new Date(),
      markings: [],
      workshopLength: 1,
      lengthFree: 1,
      workshopName: '',
      newTopic: '',
      loggedInUser: JSON.parse(localStorage.getItem('loggedInUserData')),
      candidateOptions: [
        ['present', 'Prisutan'],
        ['paid', 'Platio'],
        ['work', 'Rad'],
        ['exam', 'Ispit'],
      ],
      invalidSelectedTopic: false,
      topicAdded: false
    });

    function onInputChange() {
      data.typed = true;
      data.inputCount = data.enteredText.length;
      if (data.inputCount === 0) {
        data.typed = false;
      }

      if (data.enteredText === '') {
        userService.get.index('api/users/all')
            .then((response: AxiosResponse) => {
              users.value = response.data
            })
      } else {
        userService.get.search(data.enteredText)
        .then((response: AxiosResponse) => {
          users.value = response.data.data
        })
      }
    }

    function removeUser(user: UserType) {
      selectedUsers.value = selectedUsers.value.filter((el) => {
        return el.name != user.name;
      });
    }

    function selectUser(user: UserType) {
      data.allCandidatesSelected = false;
      if (selectedUsers.value.filter(g => g.name === user.name).length === 0) {
        selectedUsers.value.push(user)
      }
    }

    function goToStepTwo() {
      data.selectedUserScreen = true

      selectedUsers.value.forEach((candidate) => {
        candidate.present = 1;
        candidate.paid = 1;
        candidate.work = 0;
        candidate.exam = 0;
        candidate.numHours = 7 * data.workshopLength;

        data.markings.push({
          "present_active": 1,
          "paid_active": 1,
          "work_active": 0,
          "exam_active": 0,
        })
      })
    }

    function closeModal(): void {
      data.dialogOpen = false
    }

    onMounted(() => {
      userService.get
          .index('api/users/all')
          .then((response: AxiosResponse) => {
            users.value = response.data
          })

      bus
          .$on('closeModal', () => {
            data.dialogOpen = false
          })

        topicService
            .read
            .index()
            .then((response: AxiosResponse) => {
              data.topics = response.data.data
            })
      

      bus.$on('markings', (markings) => {
        data.markings = markings
      })
    })

    const modalText = computed(() => {
      let groupNames = '';
      selectedUsers.value.forEach(group => {
        groupNames += ' [' + group.name + '] '
      })
      return "Uneli ste podatke za korisnike grupa " + groupNames + ". Da li ste sigurni?"
    })

    const lengthInDays = computed(() => {
      const temp = (data.lengthFree > 1) ? data.lengthFree : data.workshopLength
      if(typeof temp === 'string') {
        return parseInt(temp)
      } else {
        return temp;
      }
    })

    function isCandidatePresentGeneric(candidateIndex, slug) {
      return data.markings[candidateIndex][slug + '_active'] ? 'active' : 'inactive';
    }

    function updateNumHours() {
      selectedUsers.value.forEach(candidate => {
        candidate.numHours = 7 * data.workshopLength
      })
      data.prefixKey++;
    }

    watch(() => data.lengthFree, (lengthFree, prevCount) => {
          data.workshopLength = lengthFree
          selectedUsers.value.forEach(candidate => {
            candidate.numHours = 7 * lengthFree
          })
          data.prefixKey++;
        }
    )

    function saveWorkShop() {

      const selectedUsersIds: number[] = selectedUsers.value.map(item => {
        return item.id
      })

      const lineItems = selectedUsers.value.map((candidate, index) => {
          return {
            "user_id": candidate.id,
            "num_hours": candidate.numHours,
            "present": data.markings[index].present_active,
            "paid": data.markings[index].paid_active,
            "work": data.markings[index].work_active,
            "exam": data.markings[index].exam_active,
          }
        })

      insert
          .insertWorkShopAdditional({
            "user_ids": selectedUsersIds,
            "session_date": data.sessionDate,
            "line_items": lineItems,
            "topic_id": data.selectedTopic,
            "category_id": 4,
            "ownerId": data.loggedInUser.id,
            "name": data.workshopName,
            "length": data.workshopLength
          })
          .then(() => {
            data.dialogOpen = false;
            data.inputCount = 0;
            data.enteredText = '';
            data.typed = false;
            selectedUsers.value = [];
            data.selectedUserScreen = false;
            data.selectedTopic = 'not-selected';
            data.buttons = [];
            data.dialogOpen = false;
            data.markings = [];
          })
    }

    function addNewTopic() {
      topicService
          .insert
          .addTopic({
            name: data.enteredTopicName
          })
          .then((response: AxiosResponse) => {
            data.selectedTopic = response.data.id
            data.newTopic = '';
            data.topicAdded = true
          })
    }

    function refreshTopics() {
      topicService
          .read
          .index()
          .then((response: AxiosResponse) => {
            data.topics = response.data.data
            setTimeout(() => {
              data.topicAdded = false
            }, 3000)
          })
    }

    async function openModal() {
        if (data.enteredTopicName === '') {
          data.invalidSelectedTopic = true;
          this.$scrollTo('#form-element')
          return;
        }

        if (data.selectedTopic === 'not-selected') {
          await addNewTopic()
          refreshTopics()
        }

      data.invalidSelectedTopic = false;
      data.dialogOpen = true
    }

    function searchTopics(input) {

      return new Promise((resolve) => {
        if (input.length < 3) {
          return resolve([])
        }

        topicService
            .read
            .search(input)
            .then((response: AxiosResponse) => {
              resolve(response.data.data)
            })
      })
    }

    function getResultValue(result) {
      return result.name
    }

    function selectTopic(result) {
      data.selectedTopic = result.id
    }

    function updateTopicName(e) {
      data.enteredTopicName = e.srcElement.value
    }

    return {
      data,
      getResultValue,
      selectTopic,
      searchTopics,
      onInputChange,
      selectUser,
      openModal,
      closeModal,
      modalText,
      saveWorkShop,
      isCandidatePresentGeneric,
      goToStepTwo,
      removeUser,
      updateNumHours,
      addNewTopic,
      updateTopicName,
      props,
      lengthInDays,

      //refs
      users,
      selectedUsers
    };
  },
});
