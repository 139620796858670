
























































import {computed, defineComponent, onMounted, reactive} from "@vue/composition-api";
import Modal from "@/components/Modal.vue";
import History from "@/components/History.vue";
import WorkshopUserItems from "@/views/workshop/WorkshopUserItem.vue";
import Datepicker from 'vuejs-datepicker';
import {bus} from "@/main";
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

export default defineComponent({
  components: {
    Datepicker,
    History,
    Modal,
    Autocomplete,
    WorkshopUserItems
  },
  props: {
    loadedCandidate: Object,
    candidateIndex: Number,
    markings: Array,
    length: Number,
    details: Object
  },
  setup(props) {

    const data = reactive({
      candidateOptions: [
        ['present', 'Prisutan'],
        ['paid', 'Platio'],
        ['work', 'Rad'],
        ['exam', 'Ispit'],
      ],
      candidateObject: {}
    });

    const lineItemDetails = computed(() => {
      return props.details
    })

    function isMarked(candidateIndex, slug) {
      return props.details[slug] ? 'active' : 'inactive'
    }

    function markCandidate(candidateIndex: number, markingType?: string) {
      props.details[markingType] = !props.details[markingType]
      if (!props.length) {
        props.length = 1;
      }
      console.log(markingType);
      if (markingType === 'present') {
        if (props.details[markingType] === true) {
          //props.details.numHours = 7
          bus.$emit('singleLineItemUpdated', {
            newValue: props.length * 7,
            lineItemId: props.details.id
          })
        } else {
          bus.$emit('singleLineItemUpdated', {
            newValue: 0,
            lineItemId: props.details.id
          })
        }
      }

      bus.$emit('detailsChanged', {
        details: props.details,
        userId: props.loadedCandidate.id,
        user: props.loadedCandidate
      })
      bus.$emit('toggleMarkingTypes', { candidateIndex, markingType })
    }

    onMounted(() => {
      data.candidateObject = props.loadedCandidate
    })

    function updateSingleLineItem(newValue, lineItem) {
      bus.$emit('singleLineItemUpdated', {
        newValue,
        lineItemId: lineItem.id
      })
    }

    return {
      data,
      markCandidate,
      isMarked,
      lineItemDetails,
      updateSingleLineItem
    };
  },
});
