




































































import {defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import Main from '@/views/layouts/Main.vue'
import commonUtils from "@/common/commonUtils.ts";
import {bus} from "@/main";
import {AxiosResponse} from "axios";
import Modal from '@/components/Modal.vue'
import {VueDatePicker} from '@mathieustan/vue-datepicker';

export default defineComponent({
  components: {
    Main,
    Modal,
    VueDatePicker
  },
  props: ['event_id'],
  destroyed() {
    bus.$destroy();
  },
  setup(props) {
    const { get, insert, actions } = commonUtils()

    const data = reactive({
      pageName: 'Korektor Individualne - Pojedinačno',
      events: [],
      editMode: false,
      loadedId: 0,
      selectedUser: 0,
      numHours: 0,
      selectedTherapist: 0,
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
      filter: null,
      loadedCandidateId: null,
      loadedOwnerId: null,
      loadedDate: null,
      loadedEvent: null,
      deleteModal: false,
      dialogOpen: true,
      eventToDelete: null,
    });

    function saveEvent() {
      insert
          .update('api/events/' + data.loadedEvent.id, {
            // eslint-disable-next-line @typescript-eslint/camelcase
            // eslint-disable-next-line @typescript-eslint/camelcase
            session_date: data.loadedDate,
            // eslint-disable-next-line @typescript-eslint/camelcase
            num_hours: data.numHours
          })
          .then(() => {
            data.editMode = false
            alert('Uspešno ste sačuvali promene.')
          })
    }

    function prepareDelete(eventId): void {
      data.eventToDelete = eventId;
      data.deleteModal = true;
    }

    function deleteEvent() {
      actions
          .remove('api/events/' + data.eventToDelete)
          .then(() => {
            data.deleteModal = false
          })
          .catch(err => console.log(err))
    }

    onMounted(() => {
      bus.$on('closeModal', () => {
        data.deleteModal = false
      })
      get
          .single('api/events/' + props.event_id)
          .then((response: AxiosResponse) => {
            data.loadedDate = response.data.session_date //moment(event.session_date).format('DD.MM.YYYY.')
            data.loadedOwnerId = response.data.owner_id
            data.loadedCandidateId = response.data.candidate_id
            data.loadedEvent = response.data
            data.numHours = response.data.num_hours

            data.editMode = true
          })

    })


    return {
      data,
      deleteEvent,
      prepareDelete,
      saveEvent
    };
  },
});
