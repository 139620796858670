

























































































import Main from '@/views/layouts/Main.vue'
import {defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import apiUtils from "@/common/utils";
import ImageUpload from "@/components/Upload.vue";
import Navigation from "@/components/Navigation.vue";
import Datepicker from 'vuejs-datepicker';
import {AxiosResponse} from "axios";
import getCurrentUserData from "@/common/userData";
import {bus} from "@/main";
import Modal from "@/components/Modal.vue";

export default defineComponent({
  components: {
    Main,
    Datepicker,
    Navigation,
    ImageUpload,
    Modal
  },
  setup(_, ctx) {
    const loadedUser = ref(null);
    const data = reactive({
      pageName: 'Fajlovi korisnika ',
      files: [],
      fileName: '',
      fileSk: null,
      fileCategory: 0,
      fileCategories: [],
      userId: 0,
      isAdmin: false,
      success: false
    });
    const {
      apiPrefix,
      read,
      insert
    } = apiUtils()


    onMounted(() => {
      const routeUserId = ctx.root.$route.params.user_id
      const user = JSON.parse(localStorage.getItem('loggedInUserData'));

      if (routeUserId) {
        data.userId = parseInt(routeUserId);
      } else {
        data.userId = user.id
      }

      if (user.role_id === 1 || user.role_id === 2) {
        data.isAdmin = true;
      }

      read.getAttachments(data.userId)
          .then((response: AxiosResponse) => {
            data.files = response.data
          })

      read.getCategories()
          .then((response: AxiosResponse) => {
            data.fileCategories = response.data
          })

      getCurrentUserData(data.userId)
          .then((response) => {
            loadedUser.value = response.value
            data.pageName = data.pageName + ' [' + loadedUser.value.name + ']'
          })

      bus.$on('closeModal', () => {
        data.success = false
      })
    })

    function fileUpload(e) {
      const files = e.target.files;
      if (files.length) data.fileSk = files[0];
    }

    function uploadFile() {
      if (!data.fileName) {
        return false;
      }

      const formData = new FormData();

      formData.append('category_id', data.fileCategory + '');
      formData.append('filename', data.fileName);
      formData.append('blobFile', data.fileSk);

      insert
          .insertAttachment(data.userId, formData)
          .then(() => {
            data.fileSk = null;
            data.fileName = null;
            data.fileCategory = 0;
            data.success = true;
            read.getAttachments(data.userId)
                .then((response: AxiosResponse) => {
                  data.files = response.data
                })
          })
    }

    return {
      data,
      apiPrefix,
      fileUpload,
      uploadFile
    };
  },
});
