













































































































import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import Main from '@/views/layouts/Main.vue'
import commonUtils from "@/common/commonUtils.ts";
import {bus} from "@/main";
import Datepicker from 'vuejs-datepicker';
import {AxiosResponse} from "axios";
import SlidingPagination from 'vue-sliding-pagination'

export default defineComponent({
  components: {
    Main,
    Datepicker,
    SlidingPagination
  },
  destroyed() {
    bus.$destroy();
  },
  setup(_, ctx) {
    const {get, post} = commonUtils()

    const data = reactive({
      pageName: 'Radionice',
      name: '',
      email: '',
      roleId: 0,
      password: '',
      parent: '',
      workshops: [],
      categories: [],
      editMode: false,
      loadedId: 0,
      selectedCategory: 0,
      selectedGroup: 0,
      selectedTopic: 0,
      groups: [],
      topics: [],
      dateFrom: '',
      dateTo: '',
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
      filter: null
    });

    function getWorkshops(query?: {
      url?: string;
      page?: number;
    }) {
      let apiUrl = 'api/workshops';

      if (query != undefined) {
        if (query.url != undefined) {
          apiUrl = query.url
        }
        if (query.page != undefined) {
          apiUrl = `api/workshops?page=${query.page}`;
        }
      }

      get
          .index(apiUrl)
          .then((response: AxiosResponse) => {
            data.workshops = response.data.data
            const totalPagesMod = (response.data.meta.pagination.total % data.pageSize)
            data.totalPages = Math.floor(response.data.meta.pagination.total / data.pageSize)
            if (totalPagesMod > 0) {
              data.totalPages++
            }
          })
          .catch(err => console.log(err))
    }

    function getGroups() {
      get
          .index('api/groups')
          .then((response: AxiosResponse) => {
            data.groups = response.data
          })
          .catch(err => console.log(err))
    }

    function getTopics() {
      get
          .index('api/topics/all')
          .then((response: AxiosResponse) => {
            data.topics = response.data
          })
          .catch(err => console.log(err))
    }

    function getCategories() {
      get
          .index('api/workshops/categories')
          .then((response: AxiosResponse) => {
            data.categories = response.data
          })
          .catch(err => console.log(err))
    }

    function applyFilter() {
      data.filter = {
        topic: data.selectedTopic,
        category: data.selectedCategory,
        group: data.selectedGroup,
        dateFrom: data.dateFrom,
        dateTo: data.dateTo
      }

      post
          .filter(`api/workshops/filter?page=${data.currentPage}`, data.filter)
          .then((response: AxiosResponse) => {
            data.workshops = response.data.data
            const totalPagesMod = (response.data.total % data.pageSize)
            data.totalPages = Math.floor(response.data.total / data.pageSize)
            if (totalPagesMod > 0) {
              data.totalPages++
            }
          })
          .catch(err => console.log(err))
    }

    function resetFilter() {
      data.selectedCategory = 0;
      data.selectedGroup = 0;
      data.selectedTopic = 0;
      data.dateFrom = '';
      data.dateTo = '';
      data.filter = null
      data.currentPage = 1
      getWorkshops()
    }

    function pageChangeHandler(selectedPage) {
      console.log(selectedPage)
      data.currentPage = selectedPage
      if (data.filter === null) {
        getWorkshops({page: data.currentPage})
      } else {
        applyFilter()
      }
    }

    onMounted(() => {
      getWorkshops()
      getGroups()
      getTopics()
      getCategories()
    })

    function openWorkShop(workshop) {
      ctx.root.$router.push({
        name: 'SingleWorkshop',
        // eslint-disable-next-line @typescript-eslint/camelcase
        params: {workshop_id: workshop.id}
      })
    }

    return {
      data,
      applyFilter,
      resetFilter,
      openWorkShop,
      pageChangeHandler
    };
  },
});
