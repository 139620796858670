

































import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import Main from '@/views/layouts/Main.vue'
import commonUtils from "@/common/commonUtils.ts";
import apiUtils from "@/common/utils";
import {AxiosResponse} from "axios";

export default defineComponent({
  components: {
    Main
  },
  props: ['post_id'],
  setup(props) {
    const {get} = commonUtils()

    const data = reactive({
      pageName: 'Vesti',
      title: '',
      content: '',
      userId: null,
      loadedPost: null
    });

    const {
      apiPrefix
    } = apiUtils()

    function getPost(postId) {
      get
          .single('api/posts/' + postId)
          .then((response: AxiosResponse) => {
            data.loadedPost = response.data
          })
    }

    onMounted(() => {
      getPost(props.post_id)
    })

    return {
      data,
      apiPrefix
    };
  },
});
