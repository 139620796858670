import { render, staticRenderFns } from "./EventsManagement.vue?vue&type=template&id=795a8aa8&"
import script from "./EventsManagement.vue?vue&type=script&lang=ts&"
export * from "./EventsManagement.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports