





























import Main from "@/views/layouts/Main.vue";
import {computed, defineComponent, onMounted, reactive, watch, ref} from "@vue/composition-api";
import Modal from "@/components/Modal.vue";
import History from "@/components/History.vue";
import WorkshopUserItem from "@/views/workshop/WorkshopUserItem.vue";
import Datepicker from 'vuejs-datepicker';
import {bus} from "@/main";
import apiUtils from "@/common/utils";
import topicUtils from "@/common/topic-utils";
import commonUtils from "@/common/commonUtils";
import {AxiosResponse} from "axios";
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import {UserType} from '@/common/types/all'

export default defineComponent({
  components: {
    Main
  },
  setup(props) {
    const data = reactive({
      heading: 'Izaberi vrstu nadoknade',
      pageName: 'Nadoknada',
    });

    return {
      data
    };
  },
});
