










































































import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import ProfileEdit from '@/components/ProfileEdit.vue'
import Main from "@/views/layouts/Main.vue";
import LoginModal from "@/components/LoginModal.vue";
import Modal from "@/components/Modal.vue";
import {bus} from "@/main";
import commonUtils from "@/common/commonUtils";
import {AxiosResponse} from "axios";
import SlidingPagination from 'vue-sliding-pagination'

interface Response {
  data: {};
}

export default defineComponent({
  components: {
    Main,
    ProfileEdit,
    LoginModal,
    Modal,
    SlidingPagination
  },
  destroyed() {
    bus.$destroy();
  },
  setup(_, ctx) {
    const { get, actions } = commonUtils()
    const data = reactive({
      pageName: 'Dosije',
      pageDescription: 'Podaci korisnika',
      users: [],
      isCandidateSelected: false,
      candidateToDelete: null,
      deleteModal: false,
      editMode: false,
      loadedProfile: null,
      isSuperAdmin: false,
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
      dialogOpen: true,
      filterName: ''
    });

    function getUsers(query?: {
      url?: string;
      page?: number;
    }) {
      const nameSearch = (data.filterName === '') ? 'joker' : data.filterName;
      let apiUrl = 'api/users/search/' + nameSearch;
      let noApiPrefix;

      if (query != undefined) {
        if (query.url != undefined) {
          apiUrl = query.url
          noApiPrefix = true
        }
        if (query.page != undefined) {
          apiUrl = `api/users/search/${nameSearch}/?page=${query.page}`;
          noApiPrefix = false
        }
      }

      get
          .index(apiUrl, noApiPrefix)
          .then((response: AxiosResponse) => {
            data.users = response.data.data
            const totalPagesMod = (response.data.total % data.pageSize)
            data.totalPages = Math.floor(response.data.total / data.pageSize)
            if (totalPagesMod > 0) {
              data.totalPages++
            }
          })
          .catch(err => console.log(err))
    }

    function selectCandidate(candidate): void {
      data.isCandidateSelected = true

      ctx.root.$router.push({
        name: 'XFilesSingle',
        params: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          user_id: candidate.id
        }
      })
    }

    function prepareDelete(candidate): void {
      data.candidateToDelete = candidate;
      data.deleteModal = true;
    }

    function deleteUser(): void {
      actions.remove('api/users/' + data.candidateToDelete.id)
          .then(() => {
            data.deleteModal = false;
            data.candidateToDelete = null;
            getUsers();
          })
          .catch(err => console.log(err))
    }

    function search() {
      getUsers();
    }

    function refreshUserData(userId: number) {
      get
          .index('api/users/' + userId)
          .then((response: Response) => {
            data.loadedProfile = response.data
          })
          .catch(err => console.log(err))
    }

    onMounted(() => {
      getUsers()

      bus.$on('refreshUserData', (userId: number) => {
        refreshUserData(userId)
      })

      bus.$on('closeLoginModal', () => {
        data.dialogOpen = false
      })

      bus.$on('closeModal', () => {
        data.deleteModal = false
      })

      data.loadedProfile = JSON.parse(localStorage.getItem('loggedInUserData'))
      data.isSuperAdmin = data.loadedProfile.role_id === 1
    })

    function recognize(roleName) {
      if (roleName === 'candidate') {
        return 'student';
      }

      if (roleName === 'admin') {
        return 'admin';
      }

      if (roleName === 'educator') {
        return 'edukator';
      }
    }

    function pageChangeHandler(selectedPage) {
      data.currentPage = selectedPage
      getUsers({ page: data.currentPage })
    }

    return {
      data,
      selectCandidate,
      prepareDelete,
      recognize,
      deleteUser,
      search,
      pageChangeHandler
    };
  },
});
