import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import Home from "@/views/Home.vue";
import Session from "@/views/events/Session.vue";
import Supervision from "@/views/events/Supervision.vue";
import XFiles from "@/views/XFiles.vue";
import Admin from "@/views/Admin.vue";
import Correction from "@/views/Correction.vue";
import Login from "@/views/Login.vue";
import Groups from "@/views/admin/Groups.vue";
import UsersManagement from "@/views/admin/UsersManagement.vue";
import MyProfile from "@/views/profile/MyProfile.vue";
import Topics from "@/views/admin/Topics.vue";
import Register from "@/views/Register.vue";
import WorkshopsManagement from "@/views/admin/WorkshopsManagement.vue";
import Activate from "@/views/Activate.vue";
import UserProfile from "@/views/profile/UserProfile.vue";
import UserEvents from "@/views/profile/UserEvents.vue";
import FileList from "@/components/FileList.vue";
import Notes from "@/components/Notes.vue";
import UserTopics from "@/components/UserTopics.vue";
import Exams from "@/components/Exams.vue";
import SingleWorkshop from "@/views/SingleWorkshop.vue";
import FilesManagement from "@/views/admin/FilesManagement.vue";
import NewsManagement from "@/views/admin/NewsManagement.vue";
import SinglePost from "@/views/admin/SinglePost.vue";
import Schedule from "@/views/admin/Schedule.vue";
import News from "@/views/News.vue";
import IndividualWorkshop from "@/views/workshop/IndividualWorkshop.vue";
import ExtraHoursWorkshop from "@/views/workshop/ExtraHoursWorkshop.vue";
import ExtraHoursEvent from "@/views/events/ExtraHoursEvent.vue";
import ExtraHours from "@/views/ExtraHours.vue";
import AlternativeHours from "@/views/workshop/AlternativeHours.vue";
import GroupSupervision from "@/views/workshop/GroupSupervision.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import SetNewPassword from "@/views/SetNewPassword.vue";
import PersonalStats from "@/views/profile/PersonalStats.vue";
import EventsManagement from "@/views/admin/EventsManagement.vue";
import SingleEventManagement from "@/views/admin/SingleEventManagement.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vesti',
    name: 'News',
    component: News,
    meta: {
      requiresAuth: true,
      isAdmin: false
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/zaboravljena-lozinka',
    name: 'ZaboravljenaLozinka',
    component: ForgotPassword
  },
  {
    path: '/nova-lozinka/:hash',
    name: 'NovaLozinka',
    component: SetNewPassword
  },
  {
    path: '/activate/:hash',
    name: 'activate',
    component: Activate
  },
  {
    path: '/individualna-seansa',
    name: 'Session',
    component: Session,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/individualna-supervizija',
    name: 'Supervision',
    component: Supervision,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/grupna-supervizija',
    name: 'GroupSupervision',
    component: GroupSupervision,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/radionica',
    name: 'IndividualWorkshop',
    component: IndividualWorkshop,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/nadoknada',
    name: 'ExtraHours',
    component: ExtraHours,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/nadoknada-event',
    name: 'ExtraHoursEvent',
    component: ExtraHoursEvent,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/nadoknada-workshop',
    name: 'ExtraHoursWorkshop',
    component: ExtraHoursWorkshop,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/alternativni-sati',
    name: 'AlternativeHours',
    component: AlternativeHours,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/radionica/:workshop_id',
    name: 'SingleWorkshop',
    component: SingleWorkshop,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/dosije',
    name: 'XFiles',
    component: XFiles,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/presek/:user_id',
    name: 'PersonalStats',
    component: PersonalStats,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/dosije/:user_id',
    name: 'XFilesSingle',
    component: UserProfile,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/dosije/:user_id/statistika',
    name: 'Stats',
    component: UserEvents,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/statistika',
    name: 'StatsStudent',
    component: UserEvents,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/fajlovi',
    name: 'FilesStudent',
    component: FileList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dosije/:user_id/dokumenta',
    name: 'Documents',
    component: FileList,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/dosije/:user_id/beleske',
    name: 'Notes',
    component: Notes,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/dosije/:user_id/teme',
    name: 'UserTopics',
    component: UserTopics,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/dosije/:user_id/ispiti',
    name: 'Exams',
    component: Exams,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/moj-dosije',
    name: 'MyProfile',
    component: MyProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/administracija',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/korekcija',
    name: 'Korekcija',
    component: Correction,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/administracija/grupe',
    name: 'Groups',
    component: Groups,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/administracija/korisnici',
    name: 'UsersManagement',
    component: UsersManagement,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/administracija/radionice',
    name: 'WorkshopsManagement',
    component: WorkshopsManagement,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/administracija/individualne/tip/:eventTypeId',
    name: 'EventsManagement',
    component: EventsManagement,
    meta: {
      requiresAuth: true,
      isAdminMod: true,
    }
  },
  {
    path: '/administracija/teme',
    name: 'Topics',
    component: Topics,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/administracija/fajlovi',
    name: 'FilesManagement',
    component: FilesManagement,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/administracija/vesti',
    name: 'NewsManagement',
    component: NewsManagement,
    meta: {
      requiresAuth: true,
      isAdminMod: true
    }
  },
  {
    path: '/administracija/vesti/:post_id',
    name: 'SinglePost',
    component: SinglePost,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/administracija/individualne/:event_id',
    name: 'SingleEventManagement',
    component: SingleEventManagement,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/administracija/raspored',
    name: 'Raspored',
    component: Schedule,
    props: true,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if no token is present
    if (localStorage.getItem('token') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      });
    } else {
      // User is logged in, retrieve their data
      const user = JSON.parse(localStorage.getItem('loggedInUserData'));

      console.log('[Accessing Admin-Gated Content]');

      // Check if the user is an admin, if so, proceed
      if (user.role_id === 1) {
        next();
      } else {
        next({
          path: '/login',
          params: { nextUrl: to.fullPath }
        });
      }
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    // For routes that are for guests only
    next({ name: '/' });
  } else {
    // No specific meta, just proceed
    next();
  }
});


export default router
