






























import {defineComponent, onMounted, reactive} from "@vue/composition-api";

export default defineComponent({
  setup(_, ctx) {
    const data = reactive({
      routeId: 0,
      userId: 0,
      isAdmin: false
    });

    onMounted(() => {
      data.routeId = parseInt(ctx.root.$route.params.user_id);
      const user = JSON.parse(localStorage.getItem('loggedInUserData'));
      data.userId = user.id
      if (user.role_id === 1 || user.role_id === 2) {
        data.isAdmin = true;
      }
    })

    return {
      data
    };
  },
});
