






























import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import Main from "@/views/layouts/Main.vue";
import Modal from "@/components/Modal.vue";
import Datepicker from 'vuejs-datepicker';
import apiUtils from "@/common/utils";
import {bus} from "@/main";
import {AxiosResponse} from "axios";

export default defineComponent({
  components: {
    Main,
    Datepicker,
    Modal
  },
  props: {
    group: Object
  },
  setup(props) {
    const {read} = apiUtils()

    const data = reactive({
      pageName: 'Istorijat',
      workshops: []
    });

    onMounted(() => {
      if (props.group !== undefined) {
        read.getWorkshopHistory(props.group.id)
            .then((response: AxiosResponse) => {
              data.workshops = response.data
            })
      }
      bus
          .$on('refreshWorkShopHistoryData', () => {
            read.getWorkshopHistory(props.group.id)
                .then((response: AxiosResponse) => {
                  data.workshops = response.data
                })
          })
    })

    return {
      data
    }
  }
});
