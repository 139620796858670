











































import {defineComponent, reactive} from "@vue/composition-api";
import Main from '@/views/layouts/Main.vue';
import Datepicker from 'vuejs-datepicker';
import {bus} from "@/main";

export default defineComponent({
  components: {
    Main,
    Datepicker
  },
  props: {
    active: Boolean,
    save: Function,
    modalType: String
  },
  setup(props) {

    function close() {
      bus.$emit('closeModal')
    }
    
    function saveAction() {
      props.save()
    }

    return {
      close,
      saveAction
    }

  }
});
