<template>
  <div>
    <div class="uploading-image">
      <img v-if="previewImage" alt="profilna" :src="previewImage"/>
    </div>

    <input class="custom-file-input" type="file" accept="image/jpeg" @change=uploadImage>
  </div>
</template>

<script>
export default {
  name: 'ImageUpload',
  data() {
    return {
      previewImage: null
    };
  },
  methods: {
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e => {
        this.previewImage = e.target.result;
        this.$emit('imageUploaded', this.previewImage);
      };
    }
  }
};
</script>

<style scoped>
.uploading-image {
  /*width: 50%;*/
}

.uploading-image img {
  width: 100%;
  height: auto;
}

.custom-file-input {
  color: transparent;
  height: 43px;
  width: 100%;
  margin-top: 20px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  content: 'Postavi profilnu sliku';
  display: flex;
  color: #303030;
  padding: 10px 15px;
  text-align: center;
  font-size: 14px;
  border: 1px solid #666;
  line-height: 14px;
  cursor: pointer;
  font-weight: 600;
  -webkit-transition: color 0.2s linear;
  transition: all 0.2s linear;
}

.custom-file-input:hover::before {
  background-color: #303030;
  color: #fff;
  transition: all 0.2s linear;
}

.custom-file-input:active {
  outline: 0;
}

.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

</style>
