import {ref} from "@vue/composition-api";
import axios from "axios";

export default function () {

    //const headers = ref({});

    function getCurrentUser() {
        let currentUser = localStorage.getItem('loggedInUserData')
        if (currentUser) {
            currentUser = JSON.parse(currentUser);
        } else {
            return null;
        }
    }

    function getCurrentUserRole() {
        if (getCurrentUser() === null) {
            return '';
        }

        return getCurrentUser().role.name
    }

    function isAdmin() {
        return getCurrentUserRole() === 'admin'
    }

    function isAdminMod() {
        return getCurrentUserRole() === 'admin' || getCurrentUserRole() === 'educator'
    }

    return {
        //apiPrefix,
        isAdmin,
        isAdminMod
    }
}
