




























































































































import {defineComponent, onMounted, reactive, watch} from "@vue/composition-api";
import Main from "@/views/layouts/Main.vue";
import History from "@/components/History.vue";
import topicUtils from "@/common/topic-utils";
import workshopUtils from "@/common/workshop-utils";
import {AxiosResponse} from "axios";
import Datepicker from 'vuejs-datepicker';
import WorkshopUserItemEdit from "@/views/workshop/WorkshopUserItemEdit.vue";
import {bus} from "@/main";
import Modal from "@/components/Modal.vue";

export default defineComponent({
  name: 'SingleWorkshop',
  data() {
    return {
      isDirty: false
    }
  },
  methods: {
    setIsDirty() {
      this.isDirty = true

      window.addEventListener("beforeunload", this.beforeMethod);
    },
    dateSelected() {
      this.$nextTick(() => {
        this.setIsDirty()
      })
    },
    beforeMethod(e) {
      const confirmationMessage = 'Imate izmene koje nisu sačuvane. Da li ste sigurni da želite da napustite radionicu?';
      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    }
  },
  mounted() {
    bus.$on('detailsChanged', () => {
      this.setIsDirty()
    })
    bus.$on('singleLineItemUpdated', () => {
      this.setIsDirty()
    })
    bus.$on('savedSuccess', () => {
      this.isDirty = false
      window.removeEventListener('beforeunload', this.beforeMethod)
    })
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      const prompter = confirm('Imate izmene koje nisu sačuvane. Da li ste sigurni da želite da napustite radionicu?')
      if (prompter) {
        window.removeEventListener('beforeunload', this.beforeMethod)
        return next()
      }
    } else {
      window.removeEventListener('beforeunload', this.beforeMethod)
      return next()
    }
  },
  components: {
    WorkshopUserItemEdit,
    Main,
    History,
    Datepicker,
    Modal
  },
  setup(_, ctx) {

    const {
      topicService
    } = topicUtils()

    const {
      workshopService
    } = workshopUtils()

    const data = reactive({
      pageName: 'Radionica',
      inputCount: 0,
      success: false,
      prefixKey: 888,
      enteredText: '',
      typed: false,
      lineItems: [],
      selectedGroups: [],
      selectedGroupScreen: false,
      allCandidatesSelected: false,
      candidates: [],
      topics: [],
      selectedTopic: 0,
      buttons: [],
      dialogOpen: false,
      sessionDate: null,
      markings: [],
      length: 1,
      lengthFree: null,
      workshopName: '',
      workshopId: null,
      loggedInUser: JSON.parse(localStorage.getItem('loggedInUserData')),
      candidateOptions: [
        ['present', 'Prisutan'],
        ['paid', 'Platio'],
        ['work', 'Rad'],
        ['exam', 'Ispit'],
      ]
    });

    onMounted(() => {

      const wId = parseInt(ctx.root.$route.params.workshop_id);
      data.workshopId = wId;

      function updateSingleNumHours(newValue, lineItemId) {

        const item = data.lineItems.find(lineItem => {
          return lineItem.id === lineItemId
        })

        item.numHours = parseInt(newValue)
      }

      workshopService
          .read
          .index(wId)
          .then((response: AxiosResponse) => {
            data.selectedGroups = response.data.groups
            response.data.line_items.forEach(lineItem => {
              data.candidates.push(lineItem.user)
            })
            data.selectedTopic = response.data.topic_id
            data.workshopName = response.data.name
            data.sessionDate = response.data.session_date
            data.lineItems = response.data.line_items

            const ids = data.lineItems.map(lineItem => {
              return lineItem.user.id
            })

            data.candidates.forEach(candidate => {
              if (ids.indexOf(candidate.id) === -1) {
                data.lineItems.push({
                  user: candidate,
                  present: 0,
                  work: 0,
                  exam: 0,
                  paid: 0,
                  id: 0
                })
              }
            })
          })

      topicService
          .read
          .indexAll()
          .then((response: AxiosResponse) => {
            data.topics = response.data
          })

      bus.$on('detailsChanged', (newDetails) => {
        data.lineItems.forEach(lineItem => {
          if (lineItem.user.id === newDetails.userId) {
            lineItem.present = newDetails.details.present;
            lineItem.work = newDetails.details.work;
            lineItem.exam = newDetails.details.exam;
            lineItem.paid = newDetails.details.paid;
            lineItem.id = newDetails.details.id
          }
        })
      })

      bus.$on('singleLineItemUpdated', (newData) => {
        updateSingleNumHours(newData.newValue, newData.lineItemId)
      })

      bus.$on('closeModal', () => {
        data.success = false
      })
    })

    function lineItemsForUser(userId) {

      const details = data.lineItems.find(lineItem => {
        if (lineItem.user)
          return userId === lineItem.user.id
      })

      if (details === undefined) {
        return {};
      }

      return {
        paid: details.paid,
        present: details.present,
        work: details.work,
        exam: details.exam,
        numHours: details.numHours,
        id: details.id
      }
    }

    watch(() => data.lengthFree, (lengthFree, prevCount) => {
      data.length = lengthFree
      data.lineItems.forEach(lineItem => {
        if(lineItem.present)
          lineItem.numHours = 7 * data.length
      })
      data.prefixKey++;
    })

    function saveWorkShop() {
      const workshop = {
        "session_date": data.sessionDate,
        "line_items": data.lineItems,
        "markings": data.markings,
        "topic_id": data.selectedTopic,
        "name": data.workshopName,
        "length": data.length
      };

      workshopService
          .update
          .single(data.workshopId, workshop)
          .then((response: AxiosResponse) => {
            data.success = true;
            bus.$emit('savedSuccess', response)
          })
    }

    function isCandidatePresentGeneric(candidateIndex, slug) {
      return data.markings[candidateIndex][slug + '_active'] ? 'active' : 'inactive';
    }

    function updateNumHours() {
      data.lineItems.forEach(lineItem => {
        if (lineItem.present)
          lineItem.numHours = 7 * data.length
      })
    }

    return {
      data,
      saveWorkShop,
      updateNumHours,
      isCandidatePresentGeneric,
      lineItemsForUser
    };
  },
});
