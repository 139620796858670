






































































import {computed, defineComponent, onMounted, reactive} from "@vue/composition-api";
import Main from '@/views/layouts/Main.vue'
import commonUtils from "@/common/commonUtils.ts";
import {bus} from "@/main";
import {AxiosResponse} from "axios";
import apiUtils from "@/common/utils";
import SlidingPagination from 'vue-sliding-pagination'

export default defineComponent({
  components: {
    Main,
    SlidingPagination
  },
  destroyed() {
    bus.$destroy();
  },
  setup() {
    const {get, actions} = commonUtils()
    const {
      read,
      insert
    } = apiUtils()

    const data = reactive({
      pageName: 'Raspored',
      schedules: [],
      page: 1,
      paginationData: {},
      paginationOwner: 'ScheduleManagement',
      name: '',
      fileSk: null,
      currentUser: null,
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
    });

    function getSchedules(query?: {
      url?: string;
      page?: number;
    }) {
      let apiUrl = 'api/schedules';

      if (query != undefined) {
        if (query.url != undefined) {
          apiUrl = query.url
        }
        if (query.page != undefined) {
          apiUrl = `api/schedules/?page=${query.page}`;
        }
      }

      get
          .index(apiUrl)
          .then((response: AxiosResponse) => {
            data.schedules = response.data.data
            const totalPagesMod = (response.data.total % data.pageSize)
            data.totalPages = Math.floor(response.data.total / data.pageSize)
            if (totalPagesMod > 0) {
              data.totalPages++
            }
          })
          .catch(err => console.log(err))
    }

    function deleteFile(fileId) {

      actions
          .remove('api/schedules/' + fileId)
          .then((response: AxiosResponse) => {
            getSchedules()
          })
          .catch(err => console.log(err))
    }

    function fileUpload(e) {
      const files = e.target.files;
      if (files.length) data.fileSk = files[0];
    }

    function uploadFile() {
      if (!data.name) {
        return false;
      }

      const formData = new FormData();

      formData.append('name', data.name);
      formData.append('blobFile', data.fileSk);

      insert
          .insertSchedule(formData)
          .then(() => {
            data.fileSk = null;
            data.name = null;
            getSchedules()
          })
    }

    const isAdmin = computed(() => {
      return (data.currentUser != null) ? data.currentUser.role.name === 'admin' : false;
    })

    const {
      apiPrefix
    } = apiUtils()

    onMounted(() => {
      data.currentUser = localStorage.getItem('loggedInUserData')
      if (data.currentUser) {
        data.currentUser = JSON.parse(data.currentUser)
      }

      getSchedules()
    })

    function pageChangeHandler(selectedPage) {
      data.currentPage = selectedPage
      getSchedules({page: data.currentPage})
    }

    return {
      data,
      deleteFile,
      fileUpload,
      uploadFile,
      apiPrefix,
      isAdmin,
      pageChangeHandler
    };
  },
});
