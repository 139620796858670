import axios from "axios";
import apiUtils from "@/common/utils";

export default function () {

  const {
    apiPrefix,
    headers
  } = apiUtils()

  function index(route: string, noApiPrefix?: boolean) {
      const apiPrefixValue = (noApiPrefix) ? '' : apiPrefix;
      return axios
        .get(apiPrefixValue + route, headers.value)
        .then((response) => {
          return response
        })
  }

  function indexPost(route: string, requestData: object) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPrefix + route, requestData, headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  function remove(route: string) {
    return new Promise((resolve, reject) => {
      axios
        .delete(apiPrefix + route, headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  function create(route: string, payload: object) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPrefix + route, payload, headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  function update(route: string, payload: object) {
    return new Promise((resolve, reject) => {
      axios
        .put(apiPrefix + route, payload, headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  function filter(route: string, payload: object) {
    return new Promise((resolve, reject) => {
      axios
        .post(apiPrefix + route, payload, headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  function filterGet(route: string) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPrefix + route, headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  function single(route: string) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPrefix + route, headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  function search(query: string) {
    return new Promise((resolve, reject) => {
      axios
        .get(apiPrefix + 'api/users/search/' + query, headers.value)
        .then((response) => {
          resolve(response)
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }

  return {
    get: {
      index,
      indexPost,
      filterGet,
      single,
      search
    },
    insert: {
      create,
      update
    },
    actions: {
      remove
    },
    post: {
      filter
    }
  }
}
