














































import {defineComponent, reactive} from "@vue/composition-api";
import axios from "axios";
import apiUtils from "@/common/utils";

export default defineComponent({
  setup() {
    const {apiPrefix} = apiUtils()
    const data = reactive({
      pageName: 'ForgotPassword',
      email: null,
      inProgress: false,
      isDone: false
    });

    function reset() {
      if (!data.email) {
        return false;
      }
      data.inProgress = true;
      axios
          .post(apiPrefix + 'api/reset-password', {
            email: data.email
          })
          .then(() => {

            data.inProgress = false;
            data.isDone = true;
          })
          .catch(error => {
            console.log(error)
            data.inProgress = false;
          });
    }

    return {
      data,
      reset
    };
  },
});
