























































import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import commonUtils from "@/common/commonUtils";
import {AxiosResponse} from "axios";
import {bus} from "@/main";
import rolecheck from "@/common/rolecheck";
import SlidingPagination from 'vue-sliding-pagination'
import ShowValue from './ShowValue.vue';

export default defineComponent({
  props: ['url', 'userId', 'category'],
  components: {
    SlidingPagination,
    ShowValue
  },
  destroyed() {
    bus.$destroy();
  },
  setup(props) {

    const {
      isAdminMod
    } = rolecheck()

    const data = reactive({
      events: [],
      total: 0,
      paginationData: {},
      page: 1,
      currentType: 0,
      lineItemDetails: {},
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
      isAdmin: false,
      extraHoursTotal: 0,
      loaderVisible: true
    });

    const { get } = commonUtils()

    function getEvents(query?: {
      url?: string;
      page?: number;
    }) {

      let apiUrl = props.url;
      let noApiPrefix;

      if (query != undefined) {
        if (query.url != undefined) {
          apiUrl = query.url
          noApiPrefix = true
        }
        if (query.page != undefined) {
          apiUrl = apiUrl + `?page=${query.page}`;
          noApiPrefix = false
        }
      }

      get
          .index(apiUrl, noApiPrefix)
          .then((response: AxiosResponse) => {
            data.events = response.data.data
            const totalPagesMod = (response.data.total % data.pageSize)
            data.totalPages = Math.floor(response.data.total / data.pageSize)
            if (totalPagesMod > 0) {
              data.totalPages++
            }

            get
                .index('api/workshops/total/' + props.userId + '/' + props.category)
                .then((response: AxiosResponse) => {
                  data.total = response.data
                })
                .catch(err => console.log(err));
            get
                .index('api/workshops/total/' + props.userId + '/4')
                .then((response: AxiosResponse) => {
                  data.extraHoursTotal = response.data
                })
                .catch(err => console.log(err));

            data.loaderVisible = false
          })
          .catch(err => console.log(err))
    }

    onMounted(() => {
      getEvents(props.url)

      const user = JSON.parse(localStorage.getItem('loggedInUserData'));
      if (user.role_id === 1 || user.role_id === 2) {
        data.isAdmin = true;
      }

    })

    function getHoursValue(workshop) {
      const line = workshop.line_items.find((item) => {
        if (item.user)
          return item.user.id === props.userId
        return false
      })

      return line.numHours
    }

    function pageChangeHandler(selectedPage) {
      data.loaderVisible = true
      data.currentPage = selectedPage
      getEvents({ page: data.currentPage })
    }

    return {
      data,
      getEvents,
      getHoursValue,
      isAdminMod,
      pageChangeHandler,
      user: props.userId
    };
  },
});
