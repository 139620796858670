import {ref} from "@vue/composition-api";
import axios from 'axios'

import apiUtils from "@/common/utils";

export default async function () {

    const {apiPrefix} = apiUtils()

    const userData = ref(0);

    function getUserData() {
        const token = localStorage.getItem('token')
        const tokens = token.split('|')

        axios
            .get(apiPrefix + 'api/me', {
                headers: {
                    Authorization: 'Bearer ' + tokens[1]
                }
            })
            .then((response) => {
                localStorage.removeItem('loggedInUserData')
                localStorage.setItem('loggedInUserData', JSON.stringify(response.data))
                userData.value = response.data
            })
            .catch(err => console.log(err))
    }

    await getUserData()

    return userData;
}
