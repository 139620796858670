
























































import {defineComponent, reactive} from "@vue/composition-api";
import Navbar from '@/components/Navbar.vue'
import Sidebar from '@/components/Sidebar.vue'

export default defineComponent({
  components: {
    Navbar,
    Sidebar
  },
  setup() {
    const data = reactive({
      pageName: 'Sadržaj aplikacije',
    });

    return {
      data,
    };
  },
});
