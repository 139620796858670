










































































import {defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import commonUtils from "@/common/commonUtils";
import {AxiosResponse} from "axios";
import Main from '@/views/layouts/Main.vue'
import Navigation from '@/components/Navigation.vue'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker';
import getCurrentUserData from "@/common/userData";

export default defineComponent({
  components: {
    Main,
    Navigation,
    Datepicker
  },
  setup(props, ctx) {
    const loadedUser = ref(null);
    const data = reactive({
      pageName: 'Ispiti ',
      exams: [],
      name: '',
      date: null,
      userId: 0,
      currentUser: null
    });
    const {get, insert} = commonUtils()

    function addExam() {
      insert.create('api/exams', {
        name: data.name,
        date: moment(data.date).format('DD.MM.YY.'),
        "user_id": data.userId
      })
          .then(() => {

            get.index('api/exams/' + data.userId)
                .then((response: AxiosResponse) => {
                  data.exams = response.data;
                })
          })
    }

    onMounted(() => {
      data.userId = parseInt(ctx.root.$route.params.user_id);

      getCurrentUserData(data.userId)
          .then((response) => {
            loadedUser.value = response.value
            data.pageName = data.pageName + ' [' + loadedUser.value.name + ']'
          })

      get.index('api/exams/' + data.userId)
          .then((response: AxiosResponse) => {
            data.exams = response.data;
          })
    })

    return {
      data,
      addExam
    };
  },
});
