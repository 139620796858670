






































import {defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import SlidingPagination from 'vue-sliding-pagination'
import commonUtils from "@/common/commonUtils";
import {AxiosResponse} from "axios";
import {bus} from "@/main";

export default defineComponent({
  props: ['url', 'category', 'userId'],
  components: {
    SlidingPagination
  },
  destroyed() {
    bus.$destroy();
  },
  setup(props) {

    const data = reactive({
      events: [],
      total: 0,
      userId: 0,
      currentType: 0,
      currentPage: 1,
      totalPages: 0,
      pageSize: 10,
    });

    const extraHoursTotal = ref<number>(0)

    const { get } = commonUtils()

    function getEvents(query?: {
      url?: string;
      page?: number;
    }) {

      let apiUrl = props.url;
      let noApiPrefix;

      if (query != undefined) {
        if (query.url != undefined) {
          apiUrl = query.url
          noApiPrefix = true
        }
        if (query.page != undefined) {
          apiUrl = apiUrl + `?page=${query.page}`;
          noApiPrefix = false
        }
      }

      get
          .index(apiUrl, noApiPrefix)
          .then((response: AxiosResponse) => {
            data.events = response.data.data.data
            const totalPagesMod = (response.data.data.total % data.pageSize)
            data.totalPages = Math.floor(response.data.data.total / data.pageSize)
            if (totalPagesMod > 0) {
              data.totalPages++
            }
            data.total = response.data.total

            if(props.category === 1) {
              get
                  .index('api/events/' + props.userId + '/attended-events/3')
                  .then((response: AxiosResponse) => {
                    extraHoursTotal.value = response.data.total
                  })
                  .catch(err => console.log(err));
            }
          })
          .catch(err => console.log(err))
    }

    onMounted(() => {
      getEvents(props.url)
    })

    function pageChangeHandler(selectedPage) {
      data.currentPage = selectedPage
      getEvents({ page: data.currentPage })
    }

    return {
      data,
      extraHoursTotal,
      pageChangeHandler
    };
  },
});
