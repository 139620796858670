import {ref} from "@vue/composition-api";
import axios from 'axios'

import apiUtils from "@/common/utils";

export default async function (userId: number) {

    const {apiPrefix, headers} = apiUtils()

    const userData = ref(0);

    await axios
        .get(apiPrefix + 'api/users/' + userId, headers.value)
        .then((response) => {
            userData.value = response.data
        })
        .catch(err => console.log(err))

    return userData;
}
