






































import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import Main from '@/views/layouts/Main.vue';
import Datepicker from 'vuejs-datepicker';
import {bus} from "@/main";
import commonUtils from "@/common/commonUtils";
import axios, {AxiosResponse} from "axios";
import apiUtils from "@/common/utils";

export default defineComponent({
  components: {
    Main,
    Datepicker
  },
  setup(props, ctx) {

    const {apiPrefix} = apiUtils()
    const data = reactive({
      pageName: 'Login Modal Confirmation',
      email: null,
      password: null,
      rememberMe: false
    });

    function getUserData() {
      const token = localStorage.getItem('token')
      const tokens = token.split('|')

      axios.get(apiPrefix + 'api/me', {
        headers: {
          Authorization: 'Bearer ' + tokens[1]
        }
      })
          .then((response: AxiosResponse) => {
            localStorage.setItem('loggedInUserData', JSON.stringify(response.data))
          }).catch(err => console.log(err))
    }

    function close() {
      ctx.root.$router.push({name: 'home'})
    }

    function logIn() {

      axios.post(apiPrefix + 'api/auth/login', {
        email: data.email,
        password: data.password
      }).then(async (response) => {

        localStorage.setItem('token', response.data.data.token)
        const remember = (data.rememberMe) ? 'yes' : 'no'
        localStorage.setItem('rememberMe', remember)

        await getUserData()

        bus.$emit('closeLoginModal')

      }).catch(err => console.log(err))
    }

    onMounted(() => {
      const user = JSON.parse(localStorage.getItem('loggedInUserData'));
      data.email = user.email
    })

    return {
      close,
      logIn,
      data
    }

  }
});
