


















































import {defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import Navbar from '@/components/Navbar.vue'
import Sidebar from '@/components/Sidebar.vue'
import Menu from '@/components/Menu.vue'

export default defineComponent({
  components: {
    Navbar,
    Sidebar,
    Menu
  },
  setup() {
    const data = reactive({
      pageName: 'Početna',
      isAdmin: false,
      isAdminMod: false,
      loading: true
    });

    onMounted(() => {

      setTimeout(() => {
        const user = JSON.parse(localStorage.getItem('loggedInUserData'));

        if (user.role.name === 'admin') {
          data.isAdminMod = true
        }
        if (user.role.name === 'educator') {
          data.isAdminMod = true
        }

        data.loading = false;

      }, 1500)

    })

    return {
      data,
    };
  },
});
