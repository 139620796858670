
import axios from "axios";
import apiUtils from "@/common/utils";


export default function () {

const {
    apiPrefix,
    headers
  } = apiUtils()


    const index = <R>(route: string, noApiPrefix?: boolean): Promise<R> => {
        const apiPrefixValue = (noApiPrefix) ? '' : apiPrefix;
        return axios
            .get(apiPrefixValue + route, headers.value)
            .then((response) => response.data)
    }

    const filter = <R>(route: string, payload: object): Promise<R> => {
        return axios
            .post(apiPrefix + route, payload, headers.value)
            .then((response) => response.data)
    }

    const remove = (route: string) => {
        return axios
            .delete(apiPrefix + route, headers.value)
            .then((response) => response.data)
    }

    return {
        apiActions : {
            index,
            filter,
            remove
        }
    }
}