

































































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import Navigation from "@/components/Navigation.vue";
import Main from "@/views/layouts/Main.vue";
import Event from "@/components/events/Event.vue";
import WorkShop from "@/components/events/WorkShop.vue";

export default defineComponent({
  components: {
    Main,
    Navigation,
    WorkShop,
    Event,
  },
  props: ["userId"],
  setup(props) {
    const data = reactive({
      events: [],
      paginationData: {},
      page: 1,
      userId: 0,
      total: 0,
      currentType: 0,
      activeEvent: null,
    });

    onMounted(() => {
      data.userId = props.userId;
    });

    function openTab(
      tab: {
        url: string;
      },
      index: number
    ) {
      data.activeEvent = index;
    }

    return {
      data,
      openTab,
      props,
    };
  },
});
