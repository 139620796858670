import axios from "axios";
import apiUtils from "@/common/utils";

export default function () {

    const {
        apiPrefix,
        headers
    } = apiUtils()

    return {
        workshopService: {
            read: {
                index: function (id: number) {
                    return new Promise((resolve, reject) => {
                        axios
                            .get(apiPrefix + 'api/workshops-single/' + id, headers.value)
                            .then((response) => {
                                resolve(response)
                            })
                            .catch(err => {
                                console.log(err);
                                reject();
                            });
                    });
                }
            },
            update: {
                single: function (id: number, workShop: {
                    session_date: any;
                    topic_id: number | string;
                    name: string;
                    length: number;
                    line_items: {
                        user_id: any;
                        num_hours: any;
                        present: any;
                        paid: any;
                        work: any;
                        exam: any;
                    }[];
                }) {
                    return new Promise((resolve, reject) => {
                        axios
                            .put(apiPrefix + 'api/workshops/' + id, workShop, headers.value)
                            .then((response) => {
                                resolve(response)
                            })
                            .catch(err => {
                                console.log(err);
                                reject();
                            });
                    });
                }
            }
        }
    }
}
