












































import {defineComponent, reactive} from "@vue/composition-api";
import axios from "axios";
import apiUtils from "@/common/utils";

export default defineComponent({
  setup(_, ctx) {
    const {apiPrefix} = apiUtils()
    const data = reactive({
      pageName: 'SetNewPassword',
      newPass: null,
      isDone: false,
      inProgress: false,
    });

    function reset() {
      data.inProgress = true;

      axios
          .post(apiPrefix + 'api/new-pass/'+ ctx.root.$route.params.hash, {
            newPass: data.newPass
          })
          .then(() => {
            data.inProgress = false;
            data.isDone = true;
          })
          .catch(error => {
            console.log(error)
            data.inProgress = false;
          });
    }

    return {
      data,
      reset
    };
  },
});
