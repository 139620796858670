
















































































































































































import {computed, defineComponent, onMounted, reactive, watch} from "@vue/composition-api";
import Modal from "@/components/Modal.vue";
import History from "@/components/History.vue";
import WorkshopUserItem from "@/views/workshop/WorkshopUserItem.vue";
import Datepicker from 'vuejs-datepicker';
import {bus} from "@/main";
import apiUtils from "@/common/utils";
import groupUtils from "@/common/group-utils";
import topicUtils from "@/common/topic-utils";
import {AxiosResponse} from "axios";
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

export default defineComponent({
  components: {
    Datepicker,
    History,
    Modal,
    Autocomplete,
    WorkshopUserItem
  },
  props: [
    'workshopTypeId',
    'heading',
    'pageName',
    'hasTopics'
  ],
  setup(props) {
    const {
      insert
    } = apiUtils()

    const {
      topicService
    } = topicUtils()

    const {
      groupService
    } = groupUtils();

    const data = reactive({
      prefixKey: 666,
      inputCount: 0,
      enteredTopicName: '',
      enteredText: '',
      typed: false,
      groups: [],
      selectedGroups: [],
      selectedGroupScreen: false,
      allCandidatesSelected: false,
      candidates: [],
      topics: [],
      selectedTopic: 'not-selected',
      buttons: [],
      dialogOpen: false,
      sessionDate: new Date(),
      markings: [],
      length: 1,
      lengthFree: 1,
      workshopName: '',
      newTopic: '',
      loggedInUser: JSON.parse(localStorage.getItem('loggedInUserData')),
      candidateOptions: [
        ['present', 'Prisutan'],
        ['paid', 'Platio'],
        ['work', 'Rad'],
        ['exam', 'Ispit'],
      ],
      workshopTypeId: 0,
      heading: '',
      pageName: '',
      invalidSelectedTopic: false,
      topicAdded: false
    });

    function onInputChange() {
      data.typed = true;
      data.inputCount = data.enteredText.length;
      if (data.inputCount === 0) {
        data.typed = false;
      }

      if (data.enteredText === '') {
        groupService.read
            .getGroupsFiltered(data.loggedInUser.id)
            .then((response: AxiosResponse) => {
              data.groups = response.data
            })
      } else {
        groupService.read
            .getGroupsFiltered(data.loggedInUser.id, data.enteredText)
            .then((response: AxiosResponse) => {
              data.groups = response.data
            })
      }
    }

    function removeGroup(group) {
      data.selectedGroups = data.selectedGroups.filter((el) => {
        return el.name != group.name;
      });
    }

    function selectGroup(group: { name: string }) {
      data.allCandidatesSelected = false;
      if (data.selectedGroups.filter(g => g.name === group.name).length === 0) {
        data.selectedGroups.push(group)
      }
    }

    function goToStepTwo() {
      data.selectedGroupScreen = true
      const selectedGroupsIds: number[] = data.selectedGroups.map(item => {
        return item.id
      })

      groupService
          .read
          .getCandidatesForGroups(selectedGroupsIds)
          .then((response: AxiosResponse) => {
            data.candidates = response.data
            data.candidates.forEach((candidate, index) => {
              data.candidates[index].present = 1;
              data.candidates[index].paid = 1;
              data.candidates[index].work = 0;
              data.candidates[index].exam = 0;
              data.candidates[index].numHours = 7 * data.length;

              data.markings.push({
                "present_active": 1,
                "paid_active": 1,
                "work_active": 0,
                "exam_active": 0,
              })
            })
          })
    }

    function goToStepTwoAll() {
      data.selectedGroupScreen = true

      groupService
          .read
          .getAllCandidates()
          .then((response: AxiosResponse) => {
            data.candidates = response.data
            data.candidates.forEach((candidate, index) => {
              data.candidates[index].present = 1;
              data.candidates[index].paid = 1;
              data.candidates[index].work = 0;
              data.candidates[index].exam = 0;
              data.candidates[index].numHours = 7 * data.length;

              data.markings.push({
                "present_active": 1,
                "paid_active": 1,
                "work_active": 0,
                "exam_active": 0,
              })
            })
          })
    }

    function closeModal(): void {
      data.dialogOpen = false
    }

    onMounted(() => {
      groupService.read
          .getGroupsFiltered(data.loggedInUser.id)
          .then((response: AxiosResponse) => {
            data.groups = response.data
          })
      bus
          .$on('closeModal', () => {
            data.dialogOpen = false
          })

      if (props.hasTopics) {
        topicService
            .read
            .index()
            .then((response: AxiosResponse) => {
              data.topics = response.data.data
            })
      }

      data.workshopTypeId = props.workshopTypeId;
      data.heading = props.heading;
      data.pageName = props.pageName;

      bus.$on('markings', (markings) => {
        data.markings = markings
      })
      bus.$on('toggleMarkingTypes', (payload) => {
        data.candidates[payload.candidateIndex][payload.markingType] = !data.candidates[payload.candidateIndex][payload.markingType]
      })
    })

    const modalText = computed(() => {
      let groupNames = '';
      data.selectedGroups.forEach(group => {
        groupNames += ' [' + group.name + '] '
      })
      return "Uneli ste podatke za korisnike grupa " + groupNames + ". Da li ste sigurni?"
    })

    function isCandidatePresentGeneric(candidateIndex, slug) {
      return data.markings[candidateIndex][slug + '_active'] ? 'active' : 'inactive';
    }

    const lineItems = computed(() => {
      return data.candidates.map(candidate => {
        return {
          "user_id": candidate.id,
          "num_hours": candidate.numHours,
          "present": candidate.present,
          "paid": candidate.paid,
          "work": candidate.work,
          "exam": candidate.exam,
        }
      })
    })

    function updateNumHours() {
      data.candidates.forEach(candidate => {
        candidate.numHours = 7 * data.length
      })
      data.prefixKey++;
    }

    watch(() => data.lengthFree, (lengthFree, prevCount) => {
          data.length = lengthFree
          data.candidates.forEach(candidate => {
            candidate.numHours = 7 * lengthFree
          })
          data.prefixKey++;
        }
    )

    function saveWorkShop() {

      let selectedGroupsIds: number[];

      if (data.allCandidatesSelected) {
        selectedGroupsIds = [1000]
      } else {
        selectedGroupsIds = data.selectedGroups.map(item => {
          return item.id
        })
      }

      insert
          .insertWorkShop({
            "group_ids": selectedGroupsIds,
            "session_date": data.sessionDate,
            "line_items": lineItems.value,
            "topic_id": data.selectedTopic,
            "category_id": props.workshopTypeId,
            "ownerId": data.loggedInUser.id,
            "name": data.workshopName,
            "length": data.length
          })
          .then(() => {
            data.dialogOpen = false;
            data.inputCount = 0;
            data.enteredText = '';
            data.typed = false;
            data.selectedGroups = [];
            data.selectedGroupScreen = false;
            data.candidates = [];
            data.selectedTopic = 'not-selected';
            data.buttons = [];
            data.dialogOpen = false;
            data.markings = [];
          })
          .catch(error => {
            console.log(error)
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          })
    }

    function addNewTopic() {
      topicService
          .insert
          .addTopic({
            name: data.enteredTopicName
          })
          .then((response: AxiosResponse) => {
            data.selectedTopic = response.data.id
            data.newTopic = '';
            data.topicAdded = true
          })
    }

    function refreshTopics() {
      topicService
          .read
          .index()
          .then((response: AxiosResponse) => {
            data.topics = response.data.data
            setTimeout(() => {
              data.topicAdded = false
            }, 3000)
          })
    }

    async function openModal() {
      if (props.hasTopics) {
        if (data.enteredTopicName === '') {
          data.invalidSelectedTopic = true;
          this.$scrollTo('#form-element')
          return;
        }

        if (data.selectedTopic === 'not-selected') {
          await addNewTopic()
          refreshTopics()
        }
      }

      data.invalidSelectedTopic = false;
      data.dialogOpen = true
    }

    function selectGroupAll() {
      data.selectedGroups = [];
      data.allCandidatesSelected = !data.allCandidatesSelected;
    }

    function searchTopics(input) {

      return new Promise((resolve) => {
        if (input.length < 3) {
          return resolve([])
        }

        topicService
            .read
            .search(input)
            .then((response: AxiosResponse) => {
              resolve(response.data.data)
            })
      })
    }

    function getResultValue(result) {
      return result.name
    }

    function selectTopic(result) {
      data.selectedTopic = result.id
    }

    function updateTopicName(e) {
      data.enteredTopicName = e.srcElement.value
    }

    return {
      data,
      getResultValue,
      selectTopic,
      searchTopics,
      onInputChange,
      selectGroup,
      openModal,
      closeModal,
      modalText,
      saveWorkShop,
      lineItems,
      isCandidatePresentGeneric,
      goToStepTwo,
      goToStepTwoAll,
      removeGroup,
      updateNumHours,
      addNewTopic,
      selectGroupAll,
      updateTopicName,
      props
    };
  },
});
