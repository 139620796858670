













































































































































import {defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import {VueDatePicker} from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';
import axios from "axios";
import apiUtils from "@/common/utils";

export default defineComponent({
  components: {
    VueDatePicker
  },
  props: [
    'eventTypeId',
    'heading',
    'pageName',
    'type'
  ],
  setup(props) {
    type Candidate = { id: number };

    const {
      apiPrefix,
      headers,
      getUsersForEducator
    } = apiUtils()

    const users = ref([])

    const data = reactive({
      inputCount: 0,
      enteredText: '',
      typed: false,
      numHours: 1,
      sessionDate: new Date(),
      eventTypes: [],
      selectedCandidate: null,
      selectedCandidateScreen: false,
      dialogOpen: false,
      loggedInUser: JSON.parse(localStorage.getItem('loggedInUserData')),
      errors: [],
      eventTypeId: 0,
      heading: '',
      pageName: '',
    });

    function getUsersFiltered() {
      axios.get(apiPrefix + 'api/users/' + data.loggedInUser.id + '/most-recent-filtered/' + data.enteredText, headers.value)
          .then((response) => {

            users.value = []

            for (const [key, value] of Object.entries(response.data)) {
              users.value.push(value)
            }

          })
          .catch(err => console.log(err))
    }

    function getEventTypes() {
      axios.get(apiPrefix + 'api/event-types', headers.value)
          .then((response) => data.eventTypes = response.data)
          .catch(err => console.log(err))
    }

    function insert() {

      const insertData = {
        ownerId: data.loggedInUser.id,
        candidateId: data.selectedCandidate.id,
        sessionDate: data.sessionDate,
        numHours: data.numHours,
        "event_type_id": props.eventTypeId,
      }

      if (!data.sessionDate) {

        data.errors.push('Molimo unesite ispravne podatke - format datuma i broj sati seanse.')

        setTimeout(() => {
          data.errors = [];
          data.dialogOpen = false;
        }, 2000)
        return false;
      }

      axios
          .post(apiPrefix + 'api/events', insertData, headers.value)
          .then(() => {
            data.selectedCandidate = {};
            data.selectedCandidateScreen = false;
            data.dialogOpen = false;

            getUsersForEducator(data.loggedInUser.id)
                .then((result) => {
                  users.value = result
                })
          })
          .catch(err => console.log(err))

    }

    async function onInputChange() {
      if (data.enteredText === '') {
        getUsersForEducator(data.loggedInUser.id)
            .then((result) => {
              users.value = []

              for (const [key, value] of Object.entries(result)) {
                users.value.push(value)
              }
            })
      } else {
        getUsersFiltered()
      }
    }

    function selectCandidate(candidate: Candidate) {
      data.selectedCandidate = candidate
      data.selectedCandidateScreen = true
    }

    onMounted(() => {
      getUsersForEducator(data.loggedInUser.id)
          .then((result) => {
            users.value = []

            for (const [key, value] of Object.entries(result)) {
              users.value.push(value)
            }
          })
      getEventTypes()

      data.eventTypeId = props.eventTypeId;
      data.heading = props.heading;
      data.pageName = props.pageName;
    })

    function backToMain() {
      data.selectedCandidateScreen = false
    }

    return {
      data,
      onInputChange,
      selectCandidate,
      insert,
      backToMain,
      props,
      users
    };
  },
});
