

















































































import {
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import Main from "@/views/layouts/Main.vue";
import Navigation from "@/components/Navigation.vue";
import ProfileEdit from "@/components/ProfileEdit.vue";
import axios, { AxiosResponse } from "axios";
import apiUtils from "@/common/utils";
import getCurrentUserData from "@/common/userData";
import Datepicker from "vuejs-datepicker";

export default defineComponent({
  components: {
    Main,
    ProfileEdit,
    Navigation,
    Datepicker,
  },
  setup(_, ctx) {
    const data = reactive({
      pageName: "Presek podataka korisnika",
      pageDescription: "Izaberite period",
      dateFrom: null,
      dateTo: null,
      showStats: false,
      numHoursIndividual: 0,
      numHoursWorkshop: 0,
      numHoursSupervision: 0,
      numHoursGroupSupervision: 0,
      numHoursAlternativeHours: 0,
      numHoursAdditionalHours: 0,
      numHoursAdditionalSupervision: 0,
    });

    const { apiPrefix, headers } = apiUtils();
    const loadedUser = ref(null);

    onMounted(() => {
      const userId = parseInt(ctx.root.$route.params.user_id);

      getCurrentUserData(userId).then((response) => {
        loadedUser.value = response.value;
        data.pageName = data.pageName + " [" + loadedUser.value.name + "]";
      });
    });

    function getPersonalStats() {
      axios
        .post(
          apiPrefix + "api/users-stats/" + loadedUser.value.id,
          {
            dateFrom: data.dateFrom,
            dateTo: data.dateTo,
          },
          headers.value
        )
        .then((response: AxiosResponse) => {
          data.showStats = true;
          data.numHoursIndividual = response.data.numHoursIndividual;
          data.numHoursWorkshop = response.data.numHoursWorkshop;
          data.numHoursSupervision = response.data.numHoursSupervision;
          data.numHoursGroupSupervision =
            response.data.numHoursGroupSupervision;
          data.numHoursAlternativeHours =
            response.data.numHoursAlternativeHours;
          data.numHoursAdditionalHours =
            response.data.numHoursAdditionalHours;
          data.numHoursAdditionalSupervision =
            response.data.numHoursAdditionalSupervision;
        })
        .catch((err) => console.log(err));
    }

    return {
      data,
      loadedUser,
      getPersonalStats,
    };
  },
});
