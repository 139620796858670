














import {defineComponent, reactive} from "@vue/composition-api";

export default defineComponent({
  setup() {
    const data = reactive({
      searchResults: [],
    });

    return {
      data,
    };
  },
});
