











import {defineComponent, reactive} from "@vue/composition-api";
import Main from '@/views/layouts/Main.vue';
import Individual from '@/views/events/Individual.vue';
import Datepicker from 'vuejs-datepicker';

export default defineComponent({
  components: {
    Main,
    Individual,
    Datepicker
  },
  setup() {

    const data = reactive({
      eventTypeId: 1,
      heading: 'Unos individualne seanse',
      pageName: 'Individualna Seansa'
    });

    return {
      data
    };
  },
});
