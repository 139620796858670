






import {defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import Navigation from '@/components/Navigation.vue'
import Main from "@/views/layouts/Main.vue";
import Accordion from "@/components/Accordion.vue";
import getCurrentUserData from "@/common/userData";

export default defineComponent({
  components: {
    Main,
    Navigation,
    Accordion
  },
  setup(_, ctx) {
    const loadedUser = ref(null);
    const data = reactive({
      pageName: 'Statistika korisnika ',
      attendedEvents: [],
      workshops: [],
      paginationData: {},
      paginationDataWorkshops: {},
      totalAttended: 0,
      totalAttendedWorkShops: 0,
      page: 1,
      userId: 0,
      isAdmin: false
    });


    onMounted(() => {
      const routeUserId = ctx.root.$route.params.user_id
      const user = JSON.parse(localStorage.getItem('loggedInUserData'));
      if (routeUserId) {
        data.userId = parseInt(routeUserId);
      } else {
        data.userId = user.id
      }

      if (user.role_id === 1 || user.role_id === 2) {
        data.isAdmin = true;
      }

      getCurrentUserData(data.userId)
          .then((response) => {
            loadedUser.value = response.value
            data.pageName = data.pageName + ' [' + loadedUser.value.name + ']'
          })
    })

    return {
      data,
    };
  },
});
