

































































import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import axios, {AxiosResponse} from 'axios'
import Main from '@/views/layouts/Main.vue'
import apiUtils from "@/common/utils";
import Modal from "@/components/Modal.vue";
import {bus} from "@/main";

export default defineComponent({
  components: {
    Main,
    Modal
  },
  setup() {
    const {apiPrefix, headers} = apiUtils()
    const data = reactive({
      pageName: 'Grupe',
      name: null,
      groups: [],
      editMode: false,
      loadedId: 0,
      showModal: false,
      modalMessage: '',
      deleteModal: false,
      groupToDelete: null
    });

    function getGroups() {
      axios.get(apiPrefix + 'api/groups', headers.value)
          .then((response: AxiosResponse) => {
            data.groups = response.data
          }).catch(err => console.log(err))
    }

    function insert() {
      if (data.editMode) {
        axios.post(apiPrefix + 'api/groups/' + data.loadedId, {
          name: data.name
        }, headers.value).then(async () => {
          getGroups()
          data.name = null
          data.editMode = false

          data.showModal = true;
          data.modalMessage = 'Uspešno ste sačuvali izmenu za grupu.'
        }).catch((error) => {
          if (error.response) {
            if(error.response.status == 500) {
              data.showModal = true;
              data.modalMessage = 'Došlo je do greške. Verovatno već postoji grupa sa tim imenom.'
            }
          }
        })
      } else {
        axios.post(apiPrefix + 'api/groups', {
          name: data.name
        }, headers.value).then(async () => {
          getGroups()
          data.name = null
          data.showModal = true;
          data.modalMessage = 'Uspešno ste dodali novu grupu.'
        }).catch((error) => {
          if (error.response) {
            if(error.response.status == 500) {
              data.showModal = true;
              data.modalMessage = 'Došlo je do greške. Verovatno već postoji grupa sa tim imenom.'
            }
          }
        })
      }
    }

    function edit(group) {
      data.name = group.name
      data.editMode = true
      data.loadedId = group.id
    }

    function remove(group) {
      data.groupToDelete = group;
      data.deleteModal = true;
    }

    const deleteGroup = () => {
      axios.delete(apiPrefix + 'api/groups/' + data.groupToDelete.id, headers.value).then(async () => {
        getGroups()
        data.name = null
        data.deleteModal = false;
        data.groupToDelete = null
      }).catch((error) => {
        if (error.response) {
          if(error.response.status == 500) {
            data.deleteModal = false;
            data.groupToDelete = null
            data.showModal = true;
            data.modalMessage = 'Došlo je do greške. Ne možete obrisati grupu koja ima korisnike.'
          }
        }
      })
    }

    onMounted(() => {
      getGroups()

      bus.$on('closeModal', () => {
        data.showModal = false
        data.modalMessage = ''
        data.groupToDelete = null;
        data.deleteModal = false;
      })
    })

    return {
      data,
      insert,
      edit,
      remove,
      deleteGroup
    };
  },
});
