




































































































































































































import axios, {AxiosResponse} from "axios";
import ImageUpload from "@/components/Upload.vue";
import Datepicker from 'vuejs-datepicker';
import {bus} from '@/main'
import getUserData from '@/common/refreshUserData'

interface UserData {
  activation_string: string;
  birthday: string;
  created_at: string;
  email: string;
  email_verified_at: string;
  faculty: string;
  group: { id: number; created_at: string; updated_at: string };
  group_id: number;
  id: number;
  individual_hours: string;
  name: string;
  parent: string;
  profile_image: string;
  role: { id: number; created_at: string; updated_at: string; name: string };
  role_id: number;
  status: boolean;
  supervision_hours: string;
  alternative_hours: string;
  updated_at: string;
  workshop_hours: string;
  profileImage: string;
}

export default {
  props: {
    loadedUser: Object || null
  },
  components: {
    Datepicker,
    ImageUpload
  },
  data() {
    return {
      editMode: false,
      groups: [],
      isDocumentViewActivated: false,
      isNotesViewActivated: false,
      isUserEventsActivated: false,
      isUserTopicsActivated: false,
      test: null,
      totalWorkshopHours: 0,
      totalIndividualHours: 0,
      hasAdminPrivileges: false,
      currentUser: null,
      headers: null,
      apiPrefix: '',
      historical: 'Istorijska vrednost'
    }
  },
  computed: {
    loadedUserData: {
      get() {
        return this.loadedUser;
      },
      set(newUserData) {
        return newUserData
      }
    },
    loadedUserGroup: {
      get() {
        if (this.loadedUser.group) {
          return this.loadedUserData.group.id
        }
        return 0;
      },
      set(groupId) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        return this.loadedUserData.group_id = groupId
      }
    },
    isMyProfileLoaded: function () {
      return this.currentUser.id === this.loadedUserData.id;
    },
    canEditProfile: function () {
      return this.hasAdminPrivileges && this.editMode
    },
    sumIndividual: function () {
      const val = parseInt(this.loadedUserData.total_individual_event_hours) + parseInt(this.loadedUserData.individual_hours)
      return (isNaN(val)) ? 0 : val;
    },
    sumAlternative: function () {
      const val = parseInt(this.loadedUserData.total_alternative_hours) + parseInt(this.loadedUserData.alternative_hours)
      return (isNaN(val)) ? 0 : val;
    },
    sumWorkshop: function () {
      const val = parseInt(this.loadedUserData.total_workshop_hours) + parseInt(this.loadedUserData.workshop_hours)
      return (isNaN(val)) ? 0 : val;
    },
    sumSupervision: function () {
      const val = parseInt(this.loadedUserData.total_supervision_hours) + parseInt(this.loadedUserData.supervision_hours)
      return (isNaN(val)) ? 0 : val;
    },
    sumGroupSupervision: function () {
      const val = parseInt(this.loadedUserData.total_group_supervision_hours) + parseInt(this.loadedUserData.group_supervision_hours)
      return (isNaN(val)) ? 0 : val;
    },
  },
  methods: {
    getHeaders: function () {
      const token = localStorage.getItem('token')
      if (token === null) {
        return '';
      }
      const tokens = token.split('|')

      return {
        headers: {
          Authorization: 'Bearer ' + tokens[1]
        }
      };
    },

    toggleEditMode: function () {
      this.editMode = !this.editMode
    },

    getGroups: function () {
      axios
          .get(this.apiPrefix + 'api/groups', this.headers)
          .then((response: AxiosResponse) => {
            this.groups = response.data
          })
          .catch(err => console.log(err))
    },

    saveProfile: function () {

      let payloadData;

      if (this.loadedUser.password !== this.loadedUser.new_password) {
        return false;
      }

      if (!this.hasAdminPrivileges) {
        payloadData = {
          "profile_image": this.loadedUser.profileImage,
        }
      } else {
        payloadData = {
          name: this.loadedUserData.name,
          email: this.loadedUserData.email,
          phone: this.loadedUserData.phone,
          groupId: this.loadedUserData.group_id,
          birthday: this.loadedUserData.birthday,
          faculty: this.loadedUserData.faculty,
          parent: this.loadedUserData.parent,
          "role_id": this.loadedUserData.role_id,
          "individual_hours": this.loadedUserData.individual_hours,
          "workshop_hours": this.loadedUserData.workshop_hours,
          "supervision_hours": this.loadedUserData.supervision_hours,
          "alternative_hours": this.loadedUserData.alternative_hours,
          "group_supervision_hours": this.loadedUserData.group_supervision_hours,
          "profile_image": this.loadedUserData.profileImage,
          password: this.loadedUserData.password,
        };
      }

      axios
          .put(this.apiPrefix + 'api/users/' + this.loadedUser.id, payloadData, this.headers)
          .then((response: AxiosResponse) => {
            if (this.isMyProfileLoaded) {
              this.loadedUserData = response.data
              this.loadedUserGroup = response.data.group_id
            } else {
              bus.$emit('refreshUserData', response.data.id)
            }

            setTimeout(() => {
              this.toggleEditMode()
            }, 500)
          })
          .catch(err => console.log(err))

    },

    saveImageBase64: function (e) {
      this.loadedUserData.profileImage = e;
    }
  },
  mounted() {

    this.apiPrefix = 'https://api.psihoterapeut.rs/';

    this.headers = this.getHeaders()

    this.getGroups()

    getUserData()
        .then(() => {
          this.currentUser = JSON.parse(localStorage.getItem('loggedInUserData'))

          if (this.currentUser.role.name === 'admin' || this.currentUser.role.name === 'educator') {
            this.hasAdminPrivileges = true
          }
        })
  },
}
